import { CardContent, Divider, Grid, Typography, makeStyles } from "@material-ui/core";

import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import componentStyles from "assets/theme/views/admin/static-page.js";

import * as S from "./styles";
import React, { useMemo } from "react";
import { getTrainingCentersContacts } from "crud/contacts.crud";
import { useWatch } from "react-hook-form";

const useStyles = makeStyles(componentStyles);

export default function TrainingCenter({ control }) {
  const provinceKeyValue = useWatch({ name: "province", control });

  const [page, setPage] = React.useState(1);

  const [trainingCenters, setTrainingCenters] = React.useState(null);

  const fetchTrainingCenters = React.useCallback(async () => {
    try {
      const response = await getTrainingCentersContacts({
        provinceKeyValue: provinceKeyValue?.keyValue,
        pageIndex: page <= 0 ? 1 : page,
        pageSize: 8,
      });

      setTrainingCenters(response);
    } catch (error) {
      console.error(error);
    }
  }, [page, provinceKeyValue]);

  React.useEffect(() => {
    fetchTrainingCenters();
  }, [fetchTrainingCenters]);

  const values = useMemo(() => {
    if (!trainingCenters?.data) return [];

    return trainingCenters.data;
  }, [trainingCenters]);

  const classes = useStyles();

  return (
    <section data-testid="training-center">
      {values.map(({ name, address, phoneNumber, email }, key) => (
        <Grid item xs={12} md={6} lg={3} key={key}>
          <S.Card>
            <CardContent>
              <S.Title>{name}</S.Title>
              <Divider />
              <S.Container>
                <S.ContainerItem>
                  <LocationOnIcon />
                  <Typography variant="caption" color="text.secondary">
                    {address}
                  </Typography>
                </S.ContainerItem>
                <S.ContainerItem>
                  <PhoneIcon />
                  <Typography variant="body2" color="text.secondary">
                    {phoneNumber}
                  </Typography>
                </S.ContainerItem>
                <S.ContainerItem>
                  <EmailIcon />
                  <a className={classes.linkRoot} href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                    {email}
                  </a>
                </S.ContainerItem>
              </S.Container>
            </CardContent>
          </S.Card>
        </Grid>
      ))}

      <S.Pagination
        count={trainingCenters?.totalPages}
        page={page}
        onChange={(_, value) => setPage(value)}
        color="primary"
        size="small"
      />
    </section>
  );
}
