import { Container as MuiContainer, styled } from "@material-ui/core";
import { Pagination as MuiPagination } from "@material-ui/lab";

export const FormContainer = styled("div")(() => ({
  marginBottom: 32,
}));

export const Container = styled(MuiContainer)(() => ({
  height: "100%",
  paddingBottom: 32,
}));

export const Form = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  '& .MuiFormGroup-root': {
    width: "410px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }
}));

export const Pagination = styled(MuiPagination)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 32,
}));