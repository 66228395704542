import React from "react";
import { useIntl } from "react-intl";
import moment from "moment";

import { useForm, FormProvider } from "react-hook-form";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import updatePersonalProfileAction from "redux/actions/updatePersonalProfileAction";

import { getPersonalInformationAction } from "redux/actions/auth/getPersonalInformationAction";
import { getSelectOptionsElementByCode, getMultipleSelectOptionsElementByCode } from "utils/formSelect";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import PasswordForm from "../Components/PasswordForm";
import FormHelperText from "@material-ui/core/FormHelperText";

import PersonalForm from "./PersonalForm.js";
import ProfessionalForm from "./ProfessionalForm.js";
import EmploymentAndUnemploymentHistory from "./EmploymentAndUnemploymentHistory";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";

import { FetchDatasetsOnMount } from "utils/datasets";
import { dataSetsNeededIndividualRegistration } from "constants/componentsDatasets";
import {
  getDataSetByNameAction,
  getGeographicDataByProvinceAction,
  getChildrenByPropertyIdAction,
} from "redux/actions/datasetsAction";
import { getReportAction } from "redux/actions/reportsAction";
import componentStylesUserProfile from "assets/theme/views/admin/user-profile.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import UserPicture from "../Components/UserPicture";
import { DATASET, USER_TYPE, REPORT_TYPES } from "utils/const";
import userProfilePersonalUpdateMap from "mappers/UserProfilePersonalUpdateMap";
import LocationHeader from "components/Headers/LocationHeader";
import { labels } from "resources/resources";
import { validateIfArrayHasCommonObjects } from "utils/validations";
import InterventionOtherAreaSection from "./Sections/InterventionOtherAreaSection";
import Tabs from "components/Tabs/Tabs";

const useStylesUserProfile = makeStyles(componentStylesUserProfile);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function UserProfile(props) {
  FetchDatasetsOnMount(props.getDataSetByName, props.datasetsState, dataSetsNeededIndividualRegistration);
  const theme = useTheme();
  const classes = { ...useStylesGeneric(), ...useStylesUserProfile() };
  const intl = useIntl();

  const TABS = {
    PERSONAL: "personal",
    PROFESSIONAL: "professional",
    EMPLOYMENT_AND_UNEMPLOYMENT_HISTORY: "employmentAndUnemploymentHistory",
    INTERVENTION_OTHER_AREAS: "interventionOtherAreasTab",
  };

  const [file, setFile] = React.useState();
  const [tabType, settabType] = React.useState(TABS.PERSONAL);
  const [changePassword, setChangePassword] = React.useState(false);
  const [someProfessionalFieldsAreEmpty, setSomeProfessionalFieldsAreEmpty] = React.useState(false);
  const [somePersonalFieldsAreEmpty, setSomePersonalFieldsAreEmpty] = React.useState(false);
  const [duplicateProfessions, setDuplicateProfessions] = React.useState(false);

  const methods = useForm({
    mode: "onChange",
  });

  React.useEffect(() => {
    props.getPersonalInformation();

    if (props.datasetsState[DATASET.LANGUAGES] === undefined || props.datasetsState[DATASET.LANGUAGES]?.length === 0) {
      props.getDataSetByName(DATASET.LANGUAGES);
    }

    if (
      props.datasetsState[DATASET.OCCUPATIONAL_CATEGORIES] === undefined ||
      props.datasetsState[DATASET.OCCUPATIONAL_CATEGORIES]?.length === 0
    ) {
      props.getDataSetByName(DATASET.OCCUPATIONAL_CATEGORIES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!props.authState.personalInformation_loading) {
      methods.reset(props.authState.personalInformation);
      methods.setValue("mobilePhoneConfirmation", props.authState.personalInformation?.mobilePhone);
      methods.setValue("emailConfirmation", props.authState.personalInformation?.email);
      methods.setValue(
        "userSingular.dateOfBirth",
        props.authState.personalInformation?.userSingular?.dateOfBirth
          ? moment(props.authState.personalInformation.userSingular.dateOfBirth)
          : ""
      );
    }

    setupDropdowns(props, methods);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authState.personalInformation_loading]);

  //#region SPECIAL_NEEDS
  React.useEffect(() => {
    if (props.datasetsState)
      methods.setValue(
        "userSingular.specialNeedsId",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.SPECIAL_NEEDS],
          props.authState.personalInformation?.userSingular?.specialNeedsId
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.SPECIAL_NEEDS]]);
  //#endregion

  //#region GENDER
  React.useEffect(() => {
    if (props.datasetsState)
      methods.setValue(
        "gender",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.GENDER],
          props.authState.personalInformation?.userSingular?.gender
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.GENDER]]);
  //#endregion

  //#region MARITAL_STATUS
  React.useEffect(() => {
    if (props.datasetsState)
      methods.setValue(
        "maritalStatus",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.MARITAL_STATUS],
          props.authState.personalInformation?.userSingular?.maritalStatus
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.MARITAL_STATUS]]);
  //#endregion

  //#region NATIONALITIES
  React.useEffect(() => {
    if (props.datasetsState)
      methods.setValue(
        "nationalityId",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.NATIONALITIES],
          props.authState.personalInformation?.userSingular?.nationality
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.NATIONALITIES]]);
  //#endregion

  //#region PROVINCES
  React.useEffect(() => {
    if (props.datasetsState && props.datasetsState[DATASET.PROVINCES]) {
      methods.setValue(
        "province",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.PROVINCES],
          props.authState.personalInformation?.userSingular?.compositeAddress?.provinceId
        )
      );

      const provinceKey = props.datasetsState[DATASET.PROVINCES].find(
        (el) => el.code === props.authState.personalInformation?.userSingular?.compositeAddress?.provinceId
      )?.keyValue;
      if (provinceKey) props.getDataSetByName(DATASET.MUNICIPALITIES, provinceKey);

      methods.setValue(
        "userSingular.intendedWorkplaceProvince",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.PROVINCES],
          props.authState.personalInformation?.userSingular?.intendedWorkplaceProvinceId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROVINCES]]);
  //#endregion

  //#region MUNICIPALITIES
  React.useEffect(() => {
    if (props.datasetsState) {
      methods.setValue(
        "municipality",
        // getDatasetStateByName(props, DATASET.MUNICIPALITIES)
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.MUNICIPALITIES],
          props.authState.personalInformation?.userSingular?.compositeAddress?.municipalityId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.MUNICIPALITIES]]);
  //#endregion

  //#region ACADEMIC_LEVEL
  React.useEffect(() => {
    if (props.datasetsState && props.datasetsState[DATASET.ACADEMIC_LEVEL]) {
      methods.setValue(
        "academicLevel",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.ACADEMIC_LEVEL],
          props.authState.personalInformation?.userSingular?.academicLevel
        )
      );
      const academicLevelKey = props.datasetsState[DATASET.ACADEMIC_LEVEL].find(
        (el) => el.code === props.authState.personalInformation?.userSingular?.academicLevelId
      )?.keyValue;
      if (academicLevelKey) props.getDataSetByName(DATASET.ACADEMIC_DEGREE, academicLevelKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.ACADEMIC_LEVEL]]);
  //#endregion

  //#region ACADEMIC_DEGREE
  React.useEffect(() => {
    if (props.datasetsState) {
      methods.setValue(
        "degree",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.ACADEMIC_DEGREE],
          props.authState.personalInformation?.userSingular?.degreeId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.ACADEMIC_DEGREE]]);
  //#endregion

  //#region PROFESSIONAL_CATEGORY
  React.useEffect(() => {
    if (props.datasetsState) {
      methods.setValue(
        "professionalCategory",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.PROFESSIONAL_CATEGORY],
          props.authState.personalInformation?.userSingular?.professionalCategory
        )
      );
      methods.setValue(
        "professionalExperience",
        getMultipleSelectOptionsElementByCode(
          props.datasetsState[DATASET.PROFESSIONAL_CATEGORY],
          props.authState.personalInformation?.userSingular?.professionalExperienceList
        )
      );

      methods.setValue(
        "secondaryProfessionalExperience",
        getMultipleSelectOptionsElementByCode(
          props.datasetsState[DATASET.PROFESSIONAL_CATEGORY],
          props.authState.personalInformation?.userSingular?.secondaryProfessionalExperienceList
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]]);
  //#endregion

  //#region PROFESSIONAL_TRAINNING
  React.useEffect(() => {
    if (props.datasetsState) {
      methods.setValue(
        "professionalTraining",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.PROFESSIONAL_TRAINNING],
          parseInt(props.authState.personalInformation?.userSingular?.professionalTraining)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROFESSIONAL_TRAINNING]]);
  //#endregion

  //#region ENTREPRENEURSHIP_TRAINNING
  React.useEffect(() => {
    if (props.datasetsState) {
      methods.setValue(
        "entrepreneurshipTraining",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.ENTREPRENEURSHIP_TRAINNING],
          parseInt(props.authState.personalInformation?.userSingular?.entrepreneurshipTraining)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.ENTREPRENEURSHIP_TRAINNING]]);
  //#endregion

  //#region SMALL_BUSINESS_MGMT_TRAINNING
  React.useEffect(() => {
    if (props.datasetsState) {
      methods.setValue(
        "smallBusinessMgmtTraining",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING],
          parseInt(props.authState.personalInformation?.userSingular?.smallBusinessMgmtTraining)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING]]);
  //#endregion

  React.useEffect(() => {
    if (props.datasetsState && props.datasetsState[DATASET.LANGUAGES]) {
      methods.setValue(
        "studiedLanguages",
        getMultipleSelectOptionsElementByCode(
          props.datasetsState[DATASET.LANGUAGES],
          props.authState.personalInformation?.userSingular?.studiedLanguagesList
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.LANGUAGES]]);

  React.useEffect(() => {
    if (props.datasetsState && props.datasetsState[DATASET.OCCUPATIONAL_CATEGORIES]) {
      methods.setValue(
        "userSingular.occupationalCategory",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.OCCUPATIONAL_CATEGORIES],
          props.authState.personalInformation?.userSingular?.occupationalCategoryId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.OCCUPATIONAL_CATEGORIES]]);

  const handletabType = (event, value) => {
    if (value !== null) {
      settabType(value);
    }
  };

  function handleChangePassword() {
    setChangePassword(true);
  }

  function handleCloseModal() {
    setChangePassword(false);
  }

  function validateProfessionalFieldsNotEmpty() {
    if (
      methods.getValues("academicLevel") &&
      methods.getValues("professionalTraining") &&
      methods.getValues("entrepreneurshipTraining") &&
      methods.getValues("smallBusinessMgmtTraining") &&
      methods.getValues("professionalExperience") &&
      methods.getValues("userSingular.experience")
    ) {
      setSomeProfessionalFieldsAreEmpty(false);
      return true;
    }

    setSomeProfessionalFieldsAreEmpty(true);
    return false;
  }

  function validatePersonalFieldsNotEmpty() {
    if (
      methods.getValues("gender") &&
      methods.getValues("maritalStatus") &&
      methods.getValues("municipality") &&
      methods.getValues("name") &&
      methods.getValues("nationalityId") &&
      methods.getValues("province") &&
      methods.getValues("userSingular.compositeAddress.district") &&
      methods.getValues("userSingular.dateOfBirth") &&
      methods.getValues("userSingular.lastName") &&
      (methods.getValues("userSingular.specialNeedsId") || methods.getValues("userSingular.specialNeedsPercent"))
    ) {
      setSomePersonalFieldsAreEmpty(false);
      return true;
    }

    setSomePersonalFieldsAreEmpty(true);
    return false;
  }

  function validateIfDuplicateProfession() {
    const mainProfessionsList = methods.getValues("professionalExperience");
    const secondaryProfessionsList = methods.getValues("secondaryProfessionalExperience");

    if (!validateIfArrayHasCommonObjects(mainProfessionsList, secondaryProfessionsList)) {
      setDuplicateProfessions(false);
      return true;
    }

    setDuplicateProfessions(true);
    return false;
  }

  const handleUpdateProfile = (data) => {
    if (validateProfessionalFieldsNotEmpty() && validatePersonalFieldsNotEmpty() && validateIfDuplicateProfession()) {
      const mappedData = userProfilePersonalUpdateMap(
        data,
        props.authState.personalInformation?.userTypeDocuments,
        file
      );

      props.updatePersonalProfile(mappedData);
    }
  };

  const showTabContent = (tabType) => {
    switch (tabType) {
      case TABS.PERSONAL:
        return (
          <FormWrapper>
            <Grid container className={classes.cardGrid} style={{ margin: 0 }}>
              <Grid item xs={12} md={3}>
                <UserPicture file={file} setFile={setFile} />
              </Grid>

              <Grid item xs={12} md={9} style={{ paddingRight: 0 }}>
                <PersonalForm />
              </Grid>
            </Grid>
          </FormWrapper>
        );
      case TABS.PROFESSIONAL:
        return (
          <FormWrapper>
            <ProfessionalForm handleChangePassword={handleChangePassword} />
          </FormWrapper>
        );
      case TABS.EMPLOYMENT_AND_UNEMPLOYMENT_HISTORY:
        return (
          <Grid item xs={12} lg={12}>
            <EmploymentAndUnemploymentHistory />
          </Grid>
        );
      case TABS.INTERVENTION_OTHER_AREAS:
        return (
          <Grid item xs={12} lg={12}>
            <InterventionOtherAreaSection />
          </Grid>
        );
      default:
        return (
          <FormWrapper>
            <PersonalForm />
          </FormWrapper>
        );
    }
  };

  function FormWrapper({ children }) {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleUpdateProfile)}>
          <Grid item xs={12} lg={12}>
            {children}

            <Grid item xs={12} md={12}>
              <Grid item xs={12} md={12}>
                {somePersonalFieldsAreEmpty && tabType === TABS.PROFESSIONAL && (
                  <FormHelperText
                    component={Box}
                    color={theme.palette.warning.main + "!important"}
                    style={{ textAlign: "right" }}
                  >
                    Valide os dados pessoais para continuar
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                {someProfessionalFieldsAreEmpty && tabType === TABS.PERSONAL && (
                  <FormHelperText
                    component={Box}
                    color={theme.palette.warning.main + "!important"}
                    style={{ textAlign: "right" }}
                  >
                    Valide os dados profissionais para continuar
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                {duplicateProfessions && (
                  <FormHelperText
                    component={Box}
                    color={theme.palette.warning.main + "!important"}
                    style={{ textAlign: "right" }}
                  >
                    Não é possível escolher a mesma opção nos campos "Profissão Principal" e "Profissão Subsidiária"
                  </FormHelperText>
                )}
              </Grid>
              <Box className={classes.boxButtonSaveProfile}>
                {/* Move the button inside the FormProvider */}
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={props.personalProfileState.update_profile_loading}
                >
                  Guardar
                </GenericButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    );
  }

  function userSingularCurriculumMap(userId) {
    return {
        reportEnumKey: REPORT_TYPES.CURRICULUM,
        parameters: JSON.stringify({ userId : userId }),
    };
}

  return (
    <>
      <GenericBackdrop open={props.authState.personalInformation_loading} />
      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
        <LocationHeader section={intl.formatMessage(labels.Label_UserProfile_UserSingular_MyProfile)} />
        <Box display="flex" alignItems="flex-start">
          <PageTitleHeader title={intl.formatMessage(labels.Label_UserProfile_UserSingular_MyProfile)} />
          {props.authState.personalInformation?.userType === USER_TYPE.INDIVIDUAL  && 
            <GenericButton
              typeSubmit={false}
              color="tertiary"
              loading={props.reportState.get_report_loading}
              onClick={() => {
                props.getReport(
                  userSingularCurriculumMap(props.authState.personalInformation?.id),
                  `${props.authState.personalInformation?.name} ${props.authState.personalInformation?.userSingular?.lastName} Curriculum.pdf`
                );
              }}
            >
              Exportar Curriculo
            </GenericButton>
          }
        </Box>
        <Box component={Grid} container justifyContent="center">
          <Grid item xs={12} lg={12} md={12}>
            <Tabs
              onChange={handletabType}
              step={tabType}
              tabs={[
                {
                  text: intl.formatMessage(labels.Label_UserProfile_UserSingular_Tabs_PersonalData),
                  value: TABS.PERSONAL,
                },
                {
                  text: intl.formatMessage(labels.Label_UserProfile_UserSingular_Tabs_ProfessionalDataAndPassword),
                  value: TABS.PROFESSIONAL,
                },
                {
                  text: intl.formatMessage(labels.Label_UserProfile_UserSingular_Tabs_EmploymentAndUnemploymentHistory),
                  value: TABS.EMPLOYMENT_AND_UNEMPLOYMENT_HISTORY,
                },
                {
                  text: intl.formatMessage(labels.Label_InterventionOtherArea),
                  value: TABS.INTERVENTION_OTHER_AREAS,
                  testId: "user-overview-intervention-other-areas-tab",
                },
              ]}
            />
            {showTabContent(tabType)}
          </Grid>
        </Box>
      </Container>

      <GenericDialog maxWidth={"sm"} open={changePassword} onClose={handleCloseModal}>
        <PasswordForm handleCloseModal={handleCloseModal} />
      </GenericDialog>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });
const mapDispatchToProps = (dispatch) => ({
  updatePersonalProfile: (data) => dispatch(updatePersonalProfileAction(data)),
  getPersonalInformation: () => dispatch(getPersonalInformationAction()),
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  getGeographicDataByProvince: (name, province) => dispatch(getGeographicDataByProvinceAction(name, province)),
  getChildrenByPropertyId: (name, id, addEmptyField) =>
    dispatch(getChildrenByPropertyIdAction(name, id, addEmptyField)),
  getReport: (id, reportFileName) => dispatch(getReportAction(id, reportFileName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

function setupDropdowns(props, methods) {
  /* #region  Special Needs */
  if (props.datasetsState && props.datasetsState[DATASET.SPECIAL_NEEDS])
    methods.setValue(
      "userSingular.specialNeedsId",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.SPECIAL_NEEDS],
        props.authState.personalInformation?.userSingular?.specialNeedsId
      )
    );
  /* #endregion */

  /* #region  Gender */
  if (props.datasetsState && props.datasetsState[DATASET.GENDER])
    methods.setValue(
      "gender",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.GENDER],
        props.authState.personalInformation?.userSingular?.genderId
      )
    );
  /* #endregion */

  /* #region  Marital Status */
  if (props.datasetsState && props.datasetsState[DATASET.MARITAL_STATUS])
    methods.setValue(
      "maritalStatus",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.MARITAL_STATUS],
        props.authState.personalInformation?.userSingular?.maritalStatusId
      )
    );
  /* #endregion */

  /* #region  Nationality */
  if (props.datasetsState && props.datasetsState[DATASET.NATIONALITIES])
    methods.setValue(
      "nationalityId",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.NATIONALITIES],
        props.authState.personalInformation?.userSingular?.nationalityId
      )
    );
  /* #endregion */

  /* #region  Province */
  if (props.datasetsState && props.datasetsState[DATASET.PROVINCES]) {
    const provinceId = props.authState.personalInformation?.userSingular?.compositeAddress?.provinceId;

    methods.setValue("province", getSelectOptionsElementByCode(props.datasetsState[DATASET.PROVINCES], provinceId));

    const provinceKey = props.datasetsState[DATASET.PROVINCES].find(
      (el) => el.code === props.authState.personalInformation?.userSingular?.compositeAddress?.provinceId
    )?.keyValue;
    if (provinceKey) props.getDataSetByName(DATASET.MUNICIPALITIES, provinceKey);

    methods.setValue(
      "userSingular.intendedWorkplaceProvince",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.PROVINCES],
        props.authState.personalInformation?.userSingular?.intendedWorkplaceProvinceId
      )
    );
  }
  /* #endregion */

  /* #region  Municipality */
  if (props.datasetsState && props.datasetsState[DATASET.MUNICIPALITIES]) {
    methods.setValue(
      "municipality",
      // getDatasetStateByName(props, DATASET.MUNICIPALITIES)
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.MUNICIPALITIES],
        props.authState.personalInformation?.userSingular?.compositeAddress?.municipalityId
      )
    );
  }

  /* #endregion */

  /* #region  Academic Level */
  if (props.datasetsState && props.datasetsState[DATASET.ACADEMIC_LEVEL]) {
    methods.setValue(
      "academicLevel",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.ACADEMIC_LEVEL],
        props.authState.personalInformation?.userSingular?.academicLevelId
      )
    );

    const academicLevelKey = props.datasetsState[DATASET.ACADEMIC_LEVEL].find(
      (el) => el.code === props.authState.personalInformation?.userSingular?.academicLevelId
    )?.keyValue;
    if (academicLevelKey) props.getDataSetByName(DATASET.ACADEMIC_DEGREE, academicLevelKey);
  }
  /* #endregion */

  /* #region  AcademicDegree */
  if (props.datasetsState && props.datasetsState[DATASET.ACADEMIC_DEGREE]) {
    methods.setValue(
      "degree",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.ACADEMIC_DEGREE],
        props.authState.personalInformation?.userSingular?.degreeId
      )
    );
  }
  /* #endregion */

  /* #region  Professional Category */
  if (props.datasetsState && props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]) {
    methods.setValue(
      "professionalCategory",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.PROFESSIONAL_CATEGORY],
        props.authState.personalInformation?.userSingular?.professionalCategoryId
      )
    );
    methods.setValue(
      "professionalExperience",
      getMultipleSelectOptionsElementByCode(
        props.datasetsState[DATASET.PROFESSIONAL_CATEGORY],
        props.authState.personalInformation?.userSingular?.professionalExperienceList
      )
    );

    methods.setValue(
      "secondaryProfessionalExperience",
      getMultipleSelectOptionsElementByCode(
        props.datasetsState[DATASET.PROFESSIONAL_CATEGORY],
        props.authState.personalInformation?.userSingular?.secondaryProfessionalExperienceList
      )
    );
  }
  /* #endregion */

  /* #region  Professional Training */
  if (props.datasetsState && props.datasetsState[DATASET.PROFESSIONAL_TRAINNING]) {
    methods.setValue(
      "professionalTraining",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.PROFESSIONAL_TRAINNING],
        parseInt(props.authState.personalInformation?.userSingular?.professionalTrainingId)
      )
    );
  }
  /* #endregion */

  /* #region  Entrepreneurship Trainning */
  if (props.datasetsState && props.datasetsState[DATASET.ENTREPRENEURSHIP_TRAINNING]) {
    methods.setValue(
      "entrepreneurshipTraining",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.ENTREPRENEURSHIP_TRAINNING],
        parseInt(props.authState.personalInformation?.userSingular?.entrepreneurshipTrainingId)
      )
    );
  }
  /* #endregion */

  /* #region Small Business Mgmt Trainning */
  if (props.datasetsState && props.datasetsState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING]) {
    methods.setValue(
      "smallBusinessMgmtTraining",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING],
        parseInt(props.authState.personalInformation?.userSingular?.smallBusinessMgmtTrainingId)
      )
    );
  }
  /* #endregion */

  if (props.datasetsState && props.datasetsState[DATASET.LANGUAGES]) {
    methods.setValue(
      "studiedLanguages",
      getMultipleSelectOptionsElementByCode(
        props.datasetsState[DATASET.LANGUAGES],
        props.authState.personalInformation?.userSingular?.studiedLanguagesList
      )
    );
  }

  if (props.datasetsState && props.datasetsState[DATASET.OCCUPATIONAL_CATEGORIES]) {
    methods.setValue(
      "userSingular.occupationalCategory",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.OCCUPATIONAL_CATEGORIES],
        props.authState.personalInformation?.userSingular?.occupationalCategoryId
      )
    );
  }
}
