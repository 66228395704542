import { React } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Controller, useFormContext } from "react-hook-form";
import ReactDatetime from "react-datetime";
import "moment/locale/pt";
//redux
import { connect } from "react-redux";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import FilledInput from "@material-ui/core/FilledInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons components
import DateRange from "@material-ui/icons/DateRange";
import HelpIcon from "@material-ui/icons/Help";
//import custom components
import FormInput from "components/FormFields/FormInput";
import FormMultipleSelect from "components/FormFields/FormMultipleSelect";
import FormSelect from "components/FormFields/FormSelect";
import GenericButton from "components/Buttons/GenericButton";

//styles
import { makeStyles, useTheme } from "@material-ui/core/styles";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStylesUserProfile from "assets/theme/views/admin/user-profile.js";
import { DATASET, REGISTRATION_CONSTS, REGEX_PATTERNS } from "utils/const";
import { getDatasetStateByName } from "utils/datasets";
import { errorMessages, labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStylesUserProfile = makeStyles(componentStylesUserProfile);

function PersonalForm(props) {
  const theme = useTheme();
  const classes = { ...useStylesGeneric(), ...useStylesUserProfile() };
  const intl = useIntl();

  const methods = useFormContext();

  const handleValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE_SIMPLE.test(date)) {
      onChange(date);
    }
  };
  function handleChangePassword() {
    props.handleChangePassword();
  }
  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader classes={{ title: classes.cardHeader }} title={"Dados Empresa"}></CardHeader>
        <CardContent>
          <Box>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} xl={6}>
                <FormInput
                  name="companyName"
                  label="Nome"
                  isRequired
                  control={methods.control}
                  errors={methods.formState.errors}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="permitCode"
                  label="Código do alvará"
                  isRequired
                  control={methods.control}
                  errors={methods.formState.errors}
                  rules={{
                    validate: {
                      maxLength: (value) => {
                        return value.length <= 25 || "O código de alvará tem tamanho máximo de 25 caracteres";
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormGroup>
                  <FormLabel>Data de validade do alvará</FormLabel>
                  <Controller
                    name="permitCodeValidUntil"
                    control={methods.control}
                    component={Box}
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                      },
                    }}
                    render={({ field }) => (
                      <ReactDatetime
                        {...field}
                        locale="pt"
                        dateFormat={REGISTRATION_CONSTS.DATE_TIME_FORMAT}
                        timeFormat={false}
                        inputProps={{
                          placeholder: REGISTRATION_CONSTS.DATE_TIME_PLACEHOLDER,
                          className: "",
                        }}
                        renderInput={(dateInputProps, open) => (
                          <OutlinedInput
                            onFocus={open}
                            fullWidth
                            {...dateInputProps}
                            onChange={(e) => {
                              handleValidDate(e.target.value, dateInputProps.onChange);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box component={DateRange} marginRight=".5rem" />
                              </InputAdornment>
                            }
                            classes={{
                              notchedOutline: clsx({
                                [classes.borderWarning]: methods.formState.errors["permitCodeValidUntil"] !== undefined,
                              }),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  {methods.formState.errors["permitCodeValidUntil"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {methods.formState.errors["permitCodeValidUntil"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="taxIdNumber"
                  label="NIF"
                  isRequired
                  control={methods.control}
                  errors={methods.formState.errors}
                  disabled={true}
                  rules={{
                    validate: {
                      maxLength: (value) => {
                        return value.length <= 14 || "O NIF deve ter até 14 caracteres";
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} xl={9}>
                <FormGroup>
                  <FormLabel>Sector Económico</FormLabel>
                  <FormMultipleSelect
                    control={methods.control}
                    fieldName="businessPurpose"
                    selectOptions={getDatasetStateByName(props, DATASET.BUSINESS_PURPOSE)}
                    getValues={methods.getValues}
                    limitTags={1}
                    errors={methods.formState.errors}
                    classes={classes}
                    required={true}
                    requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  />
                  {methods.formState.errors["businessPurpose"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {methods.formState.errors["businessPurpose"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormGroup>
                  <FormLabel>Província</FormLabel>
                  <FormSelect
                    control={methods.control}
                    fieldName="province"
                    selectOptions={getDatasetStateByName(props, DATASET.PROVINCES)}
                    loadChildrenDataSet={props.getDataSetByName}
                    childrenDataSet={DATASET.MUNICIPALITIES}
                    childrenSelect="municipality"
                    setValue={methods.setValue}
                    getValues={methods.getValues}
                    required={true}
                    requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                  {methods.formState.errors["province"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {methods.formState.errors["province"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormGroup>
                  <FormLabel>Município</FormLabel>
                  <FormSelect
                    control={methods.control}
                    fieldName="municipality"
                    selectOptions={getDatasetStateByName(props, DATASET.MUNICIPALITIES)}
                    getValues={methods.getValues}
                    required={true}
                    requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                  {methods.formState.errors["municipality"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {methods.formState.errors["municipality"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="district"
                  label="Bairro"
                  isRequired
                  control={methods.control}
                  errors={methods.formState.errors}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="address"
                  label="Endereço"
                  isRequired
                  control={methods.control}
                  errors={methods.formState.errors}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="niss"
                  label={
                    <>
                      NISS
                      <Tooltip title="Número de Identificação da Segurança Social">
                        <HelpIcon className={classes.tooltipInfoIcon} />
                      </Tooltip>
                    </>
                  }
                  isRequired
                  control={methods.control}
                  errors={methods.formState.errors}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="companyEmail"
                  label="Email da empresa"
                  isRequired
                  control={methods.control}
                  errors={methods.formState.errors}
                  rules={{
                    validate: {
                      validEmail: (value) => {
                        if (value && !REGEX_PATTERNS.EMAIL.test(value))
                          return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="companyPhone"
                  label="Telefone da empresa"
                  isRequired
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType={true}
                  rules={{
                    validate: {
                      validPhoneNumber: (value) => {
                        if (
                          value &&
                          (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                            parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                        )
                          return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="experience"
                  label="Anos de experiência"
                  isRequired
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType={true}
                  rules={{
                    validate: {
                      minValue: (value) => {
                        return value >= 0 || intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_CompanyEACId)}
                  </FormLabel>
                  <FormSelect
                    control={methods.control}
                    fieldName="companyEACId"
                    selectOptions={getDatasetStateByName(props, DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION)}
                    setValue={methods.setValue}
                    getValues={methods.getValues}
                    required={true}
                    requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                  {methods.formState.errors["companyEACId"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {methods.formState.errors["companyEACId"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="propertyType"
                  label={intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_PropertyType)}
                  isRequired
                  control={methods.control}
                  errors={methods.formState.errors}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="subsidiaryCompany"
                  label={intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompany)}
                  control={methods.control}
                  errors={methods.formState.errors}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="subsidiaryCompanyPostalCode"
                  label={intl.formatMessage(
                    labels.Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyPostalCode
                  )}
                  control={methods.control}
                  errors={methods.formState.errors}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="subsidiaryCompanyActivity"
                  label={intl.formatMessage(
                    labels.Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyActivity
                  )}
                  control={methods.control}
                  errors={methods.formState.errors}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyEAC)}
                  </FormLabel>
                  <FormSelect
                    control={methods.control}
                    fieldName="subsidiaryCompanyEACId"
                    selectOptions={getDatasetStateByName(props, DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION)}
                    setValue={methods.setValue}
                    getValues={methods.getValues}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="maleWorkersNumber"
                  label={intl.formatMessage(
                    labels.Label_UserProfile_UserCollective_CompanyData_MaleWorkersNumber
                  )}
                  control={methods.control}
                  errors={methods.errors}
                  numberType={true}
                  rules={{
                    validate: {
                      validNumber: (value) => {
                        if (value && parseInt(value) < 0) return intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <FormInput
                  name="femaleWorkersNumber"
                  label={intl.formatMessage(
                    labels.Label_UserProfile_UserCollective_CompanyData_FemaleWorkersNumber
                  )}
                  control={methods.control}
                  errors={methods.errors}
                  numberType={true}
                  rules={{
                    validate: {
                      validNumber: (value) => {
                        if (value && parseInt(value) < 0) return intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Card className={classes.cardRoot}>
        <CardHeader classes={{ title: classes.cardHeader }} title={"Palavra-passe"}></CardHeader>
        <CardContent>
          <Box display="flex">
            <Grid item xs={10} md={4} className={classes.paddingRight0px}>
              <FormGroup>
                <FormLabel className={classes.boxNameCategory}>Palavra-passe</FormLabel>
                <FilledInput
                  disabled
                  className={classes.borderRadiusSemiCurved}
                  placeholder="**************"
                ></FilledInput>
              </FormGroup>
            </Grid>
            <Box paddingTop="22.5px" paddingBottom="23px">
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                onClick={handleChangePassword}
                classes={classes.noLeftBorderRadius}
              >
                {" "}
                Alterar palavra-Passe
              </GenericButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PersonalForm);
