import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import { withRouter } from "react-router";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import FormSelect from "components/FormFields/FormSelect";
import OpportunityData from "../../../Components/Opportunities/OpportunityData";
import { getDatasetStateByName } from "utils/datasets";
import { getSelectOptionsElementByKeyValue } from "utils/formSelect";

import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { DATASET, OPPORTUNITY_TYPE_KEY } from "utils/const";

import { errorMessages, titles } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityCreation(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const methods = useFormContext();
  const [opportunityType, setOpportunityType] = useState(undefined);

  useEffect(() => {
    if (
      props.datasetsState[DATASET.OPPORTUNITY_TYPE] === undefined ||
      props.datasetsState[DATASET.OPPORTUNITY_TYPE]?.length === 0
    ) {
      props.getDataSetByName(DATASET.OPPORTUNITY_TYPE);
    } else {
      setOpportunitytypeFormSelect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpportunitytypeFormSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.OPPORTUNITY_TYPE]]);

  const setOpportunitytypeFormSelect = () => {
    if (props.match?.params?.oppType === OPPORTUNITY_TYPE_KEY.EMPREGO_URL) {
      let auxData = [];
      auxData["opportunityTypeKey"] = OPPORTUNITY_TYPE_KEY.EMPREGO;
      setOpportunityType(auxData);
      methods.setValue(
        "opportunityType",
        getSelectOptionsElementByKeyValue(
          props.datasetsState[DATASET.OPPORTUNITY_TYPE],
          OPPORTUNITY_TYPE_KEY.EMPREGO
        )
      );
    } else if (
      props.match?.params?.oppType ===
      OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL_URL
    ) {
      let auxData = [];
      auxData["opportunityTypeKey"] = OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL;
      setOpportunityType(auxData);
      methods.setValue(
        "opportunityType",
        getSelectOptionsElementByKeyValue(
          props.datasetsState[DATASET.OPPORTUNITY_TYPE],
          OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL
        )
      );
    }
  };
  const controlOpportunityType = (selectedType) => {
    if (selectedType) {
      let auxData = [];
      auxData["opportunityTypeKey"] = selectedType.keyValue;
      switch (selectedType.keyValue) {
        case OPPORTUNITY_TYPE_KEY.EMPREGO:
          window.history.replaceState(
            null,
            "",
            `/admin/opportunities-create/employment`
          );
          break;
        case OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL:
          window.history.replaceState(
            null,
            "",
            `/admin/opportunities-create/internship`
          );
          break;
        default:
          window.history.replaceState(null, "", `/admin/opportunities-create/`);
      }
      setOpportunityType(auxData);
    } else {
      setOpportunityType(undefined);
    }
    unregisterAndClearAllFieldForms();
  };
  const unregisterAndClearAllFieldForms = () => {
    methods.setValue("province", undefined);
    methods.setValue("timetableType", undefined);
    methods.setValue("description", "");
    methods.setValue("amount", "");
    methods.setValue("numVacancies", "");
    methods.setValue("reference", "");
    methods.setValue("opportunityProfessionalCategories", undefined);
    methods.setValue("contractType", undefined);
    methods.setValue("internshipType", undefined);
    methods.setValue("validFrom", undefined);
    methods.setValue("validTo", undefined);
    methods.setValue("mentorName", undefined);
    methods.setValue("mentorMobilePhone", undefined);
    methods.unregister([
      "province",
      "timetableType",
      "description",
      "amount",
      "numVacancies",
      "reference",
      "contractType",
      "internshipType",
      "opportunityProfessionalCategories",
      "validFrom",
      "validTo",
      "mentorName",
      "mentorMobilePhone",
    ]);

    unregisterAndClearQuestionFields();
  };
  const unregisterAndClearQuestionFields = () => {
    props.opportunityQuestionsState?.forEach((q) => {
      methods.setValue(q.name, "");
      methods.unregister(q.name);
    });
    props.setOpportunityQuestionsState((prevState) => {
      prevState.length = 0;
      return prevState;
    });
  };
  return (
    <>
      <Container maxWidth={false}>
        <Card className={classes.cardRoot}>
          <CardHeader
            classes={{
              title: classes.cardHeader,
            }}
            title={intl.formatMessage(
              titles.Title_Opportunity_Creation_OpportunityType_Card
            )}
          />
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={3}>
                <FormGroup>
                  <FormLabel>Tipo de Oferta</FormLabel>
                  <FormSelect
                    control={methods.control}
                    fieldName="opportunityType"
                    selectOptions={getDatasetStateByName(
                      props,
                      DATASET.OPPORTUNITY_TYPE
                    )}
                    getValues={methods.getValues}
                    setValue={methods.setValue}
                    onOptionChange={controlOpportunityType}
                    required={true}
                    requiredMessage={intl.formatMessage(
                      errorMessages.ErrorMessage_FieldRequired
                    )}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                  {methods.formState.errors["opportunityType"] !==
                    undefined && (
                    <FormHelperText
                      component={Box}
                      color={theme.palette.warning.main + "!important"}
                    >
                      {methods.formState.errors["opportunityType"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {opportunityType !== undefined && (
          <OpportunityData
            isCreationPage={true}
            isEditable={true}
            opportunityDetailsData={opportunityType}
            opportunityType={opportunityType}
            setOpportunityQuestionsState={props.setOpportunityQuestionsState}
            opportunityQuestionsState={props.opportunityQuestionsState}
          />
        )}
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) =>
    dispatch(getDataSetByNameAction(name, parentKeyValue)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OpportunityCreation));
