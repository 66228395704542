import jwtDecode from "jwt-decode";

/**
 * @module utils/getKeyValueFromToken
 * @typedef {Object} KeyValues
 * @property {string} PROVINCE_ID - Id da província
 * @property {string} MUNICIPALITY_ID - Id do município
 * @property {string} EMPLOYMENT_CENTER_ID - Id do centro de emprego
 * @property {string} NAME - Id do usuário
 */

export default class TokenHelper {
  #token = "";
  #values = {};

  constructor(token) {
    this.#token = token;

    if (token) {
      this.#values = this.#getKeyValueFromToken();
    }
  }

  /**
   * Função para obter o valor de uma chave do token
   * @returns {KeyValues} Retorna o valor da chave do token
   */
  #getKeyValueFromToken() {
    const decodedToken = jwtDecode(this.#token);

    return {
      PROVINCE_ID: decodedToken.ProvinceId,
      MUNICIPALITY_ID: decodedToken.MunicipalityId,
      EMPLOYMENT_CENTER_ID: decodedToken.EmploymentCenterId,
      NAME: decodedToken.name,
    };
  }

  /**
   * Função para obter o id do usuário
   * @returns {string} Retorna o id do usuário
   * @example
   * const tokenHelper = new TokenHelper(token);
   * const userId = tokenHelper.getUserId();
   * console.log(userId); // "dc4b1bfc0a1e234a7bf80aac6406e302"
   */
  getUserId() {
    return this.#values.NAME;
  }

  /**
   * Função para obter a província do usuário
   * @returns {number} Retorna a província do usuário
   * @example
   * const tokenHelper = new TokenHelper(token);
   * const provinceId = tokenHelper.getProvinceId();
   * console.log(provinceId); // 1
   */
  getProvinceId() {
    if (!this.#values.PROVINCE_ID) {
      return null;
    }

    return Number(this.#values.PROVINCE_ID);
  }

  /**
   * Função para obter o municiípio do usuário
   * @returns {number} Retorna o município do usuário
   * @example
   * const tokenHelper = new TokenHelper(token);
   * const municipalityId = tokenHelper.getName();
   * console.log(municipality); // 1
   */
  getMunicipalityId() {
    if (!this.#values.MUNICIPALITY_ID) {
      return null;
    }

    return Number(this.#values.MUNICIPALITY_ID);
  }

  /**
   * Função para obter o centro de emprego do usuário
   * @returns {number} Retorna o centro de emprego do usuário
   * @example
   * const tokenHelper = new TokenHelper(token);
   * const employmentCenterId = tokenHelper.getName();
   * console.log(employmentCenterId); // 1
   */
  getEmploymentCenterId() {
    if (!this.#values.EMPLOYMENT_CENTER_ID) {
      return null;
    }

    return Number(this.#values.EMPLOYMENT_CENTER_ID);
  }
}
