import { DATASET } from "utils/const";
//#region IndividualRegistration
export const dataSetsNeededIndividualRegistration = [
  DATASET.NATIONALITIES,
  DATASET.PROVINCES,
  DATASET.GENDER,
  DATASET.MARITAL_STATUS,
  DATASET.ACADEMIC_LEVEL,
  DATASET.PROFESSIONAL_CATEGORY,
  DATASET.PROFESSIONAL_TRAINNING,
  DATASET.ENTREPRENEURSHIP_TRAINNING,
  DATASET.SMALL_BUSINESS_MGMT_TRAINNING,
  DATASET.SPECIAL_NEEDS,
];
//#endregion

//#region ColectiveRegistration
export const dataSetsNeededColectiveRegistration = [
  DATASET.BUSINESS_PURPOSE,
  DATASET.PROVINCES,
  DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION,
];
//#endregion

//#region Subprogram applications
export const dataSetsNeededApplications = [DATASET.PROVINCES];
export const YES_OR_NO_DROPDOWN = [
  { code: true, label: "Sim" },
  { code: false, label: "Não" },
];
//#endregion
