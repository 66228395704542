import { Card as MuiCard, styled } from "@material-ui/core";
import { Pagination as MuiPagination } from "@material-ui/lab";

export const Card = styled(MuiCard)(() => ({
  minHeight: 'calc(100% - 32px)',
  "& .MuiCardContent-root": {
    padding: 16,
  },
}));

export const Title = styled("h5")(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: "700",
  color: theme.palette.primary.dark,
}));

export const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gridGap: 8,
  marginTop: 16,
}));

export const ContainerItem = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gridGap: 8,
  color: theme.palette.black.main,
  "& a": {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: "ellipsis",
  },
}));

export const Pagination = styled(MuiPagination)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 32,
}));
