import { React, useEffect, useState } from "react";
import { connect } from "react-redux";

import { DATASET } from "utils/const";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";

//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import SubmitedDocumentsSection from "components/FormFields/SubmitedDocumentsSection";
import { getUserDocumentAction } from "redux/actions/auth/getPersonalInformationAction";
import DisplayField from "components/FormFields/DisplayField";
import { getDatasetLabelByCode, getDatasetConcatenatedListOfLabelsByCode } from "utils/datasets";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CandidateProfessionalDetails(props) {
  const classes = useStylesGeneric();
  const [data, setData] = useState();

  useEffect(() => {
    if (props.opportunitiesState?.candidateInfo !== null && props.opportunitiesState?.candidateInfo !== undefined) {
      setData(props.opportunitiesState.candidateInfo);
    } else {
      setData([]);
    }
  }, [props.opportunitiesState?.candidateInfo]);

  //#region DataSets Setup
  useEffect(() => {
    handleGetDataset(DATASET.ACADEMIC_DEGREE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.ACADEMIC_DEGREE]]);

  useEffect(() => {
    handleGetDataset(DATASET.PROFESSIONAL_CATEGORY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]]);

  useEffect(() => {
    handleGetDataset(DATASET.PROFESSIONAL_TRAINNING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROFESSIONAL_TRAINNING]]);

  useEffect(() => {
    handleGetDataset(DATASET.ENTREPRENEURSHIP_TRAINNING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.ENTREPRENEURSHIP_TRAINNING]]);

  useEffect(() => {
    handleGetDataset(DATASET.SMALL_BUSINESS_MGMT_TRAINNING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING]]);

  useEffect(() => {
    handleGetDataset(DATASET.ACADEMIC_LEVEL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.ACADEMIC_LEVEL]]);

  useEffect(() => {
    handleGetDataset(DATASET.LANGUAGES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.LANGUAGES]]);

  useEffect(() => {
    handleGetDataset(DATASET.OCCUPATIONAL_CATEGORIES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.OCCUPATIONAL_CATEGORIES]]);

  useEffect(() => {
    handleGetDataset(DATASET.PROVINCES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROVINCES]]);
  //#endregion

  const handleGetDataset = (dataset) => {
    if (props.datasetsState[dataset] === undefined || props.datasetsState[dataset].length === 0) {
      props.getDataSetByName(dataset);
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <Card className={classes.cardRoot}>
          <CardHeader classes={{ title: classes.cardHeader }} title={"Dados Profissionais"} />
          <CardContent>
            <Grid container>
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Nível Académico"
                data={getDatasetLabelByCode(
                  props.datasetsState[DATASET.ACADEMIC_LEVEL],
                  data?.userSingular?.academicLevelId
                )}
              />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Curso"
                data={getDatasetLabelByCode(props.datasetsState[DATASET.ACADEMIC_DEGREE], data?.userSingular?.degreeId)}
              />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Categoria Profissional"
                data={getDatasetLabelByCode(
                  props.datasetsState[DATASET.PROFESSIONAL_CATEGORY],
                  data?.userSingular?.professionalCategoryId
                )}
              />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Formação Profissional"
                data={getDatasetLabelByCode(
                  props.datasetsState[DATASET.PROFESSIONAL_TRAINNING],
                  data?.userSingular?.professionalTrainingId
                )}
              />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Formação Empreendorismo"
                data={getDatasetLabelByCode(
                  props.datasetsState[DATASET.ENTREPRENEURSHIP_TRAINNING],
                  data?.userSingular?.entrepreneurshipTrainingId
                )}
              />

              {/* New Line md */}
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Formação de gestão de pequenos negócios"
                data={getDatasetLabelByCode(
                  props.datasetsState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING],
                  data?.userSingular?.smallBusinessMgmtTrainingId
                )}
              />

              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Línguas estudadas"
                data={getDatasetConcatenatedListOfLabelsByCode(
                  props.datasetsState[DATASET.LANGUAGES],
                  data?.userSingular?.studiedLanguagesList
                )}
              />

              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Categoria Ocupacional"
                data={getDatasetLabelByCode(
                  props.datasetsState[DATASET.OCCUPATIONAL_CATEGORIES],
                  data?.userSingular?.occupationalCategoryId
                )}
              />

              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Local Trabalho Pretendido"
                data={getDatasetLabelByCode(
                  props.datasetsState[DATASET.PROVINCES],
                  data?.userSingular?.intendedWorkplaceProvinceId
                )}
              />

              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Profissão Principal"
                data={getDatasetConcatenatedListOfLabelsByCode(
                  props.datasetsState[DATASET.PROFESSIONAL_CATEGORY],
                  data?.userSingular?.professionalExperienceList
                )}
              />

              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Anos de Experiência Principal"
                data={data?.userSingular?.experience}
              />

              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Profissão Subsidiária"
                data={getDatasetConcatenatedListOfLabelsByCode(
                  props.datasetsState[DATASET.PROFESSIONAL_CATEGORY],
                  data?.userSingular?.secondaryProfessionalExperienceList
                )}
              />

              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Anos de Experiência Subsidiária"
                data={data?.userSingular?.secondaryExperience}
              />

              <DisplayField
                xs={12}
                label="Qualificação Profissional (conhecimento máquinas, etc.)"
                data={data?.userSingular?.professionalQualification}
              />

              <DisplayField
                xs={12}
                label="Outras Experiências Profissionais"
                data={data?.userSingular?.otherProfessionalExperience}
              />

              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel className={classes.detailFormLabels}>Documentos</FormLabel>
                  <Grid container component={Box} className={classes.documentBadgeContainer}>
                    <SubmitedDocumentsSection
                      files={data?.opportunityCandidateDocuments?.map((doc) => {
                        return {
                          documentId: doc.id,
                          documentName: doc.documentPath,
                          documentType: doc.documentType,
                        };
                      })}
                      getDocument={props.getUserDocument}
                    />
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  getUserDocument: (docId, docName) => dispatch(getUserDocumentAction(docId, docName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfessionalDetails);
