import React from "react";

import { makeStyles, styled } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const StyledButton = styled(ToggleButtonGroup)(({ fullWidth, disableGutters }) => ({
  gridTemplateColumns: `repeat(${fullWidth ? "auto-fit" : "auto-fill"}, minmax(min(310px, 100%), 1fr))`,
  margin: disableGutters ? 0 : "0 15px"
}));

export default function Tabs({ tabs, dataTestId, onChange, step, fullWidth = false, disableGutters = false }) {
  const classes = useStylesGeneric();

  return (
    <StyledButton
      value={step}
      exclusive
      onChange={onChange}
      aria-label="tab Step"
      className={classes.changeTabsGroup}
      data-testid={dataTestId}
      fullWidth={fullWidth}
      disableGutters={disableGutters}
    >
      {tabs.map(
        ({ renderIf = true, value, testId, text }, key) =>
          renderIf && (
            <ToggleButton
              key={key}
              value={value}
              className={classes.changeTabs + " " + classes.changeTabsLarge}
              data-testid={testId}
            >
              {text}
            </ToggleButton>
          )
      )}
    </StyledButton>
  );
}
