import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  /*
    SECTIONS:
    - Generic
    - Alerts
    - Avatar
    - Buttons
    - Breadcrumbs
    - Containers
    - Cards
    - Documents
    - Forms
    - Icons
    - Input Fields
    - Labels
    - Pagination
    - Tables
    - Tabs
    - Tooltips
    - Steppers
    - Switches
    - Modals
    - IFrames
    */

  //Generic
  paddingRight0px: {
    paddingRight: "0px",
  },
  paddingLeft0px: {
    paddingLeft: "0px",
  },
  noPadding: {
    padding: "0px",
  },
  minHeight0PX: {
    minHeight: "auto!important",
  },
  textAlignRight: {
    textAlign: "right",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  textAlignEnd: {
    textAlign: "end",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  sideBySideDiv: {
    float: "left",
    marginRight: "10px",
  },
  oneLineEllipsis: {
    display: "-webkit-box",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": "1",
    lineHeight: "1.5rem",
    height: "1.5rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  doubleLineEllipsis: {
    display: "-webkit-box",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": "2",
    lineHeight: "1.5rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dropzone: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "1px",
    borderRadius: "1px",
    height: "62px!important",
    width: "100%",
    borderColor: theme.palette.gray[600],
    borderStyle: "dashed",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.gray[500],
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
  },
  preventWordOverflow: {
    overflowWrap: "anywhere",
    "& img": {
      maxWidth: "100%",
    },
  },
  hideDivBelowXS: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  noLeftBorderRadius: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  errorMessage: {
    color: theme.palette.warning.main,
    fontSize: "12px",
  },

  //Alerts
  alertMessage: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "21px",
  },

  //Avatar
  avatarRoot: {
    width: "35px",
    height: "35px",
    fontWeight: 600,
    fontSize: "12.25px",
    lineHeight: "17px",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
  },
  avatarIconOutlined: {
    padding: "2px",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.darkBlue,
    borderRadius: "28px",
    border: "1px solid " + theme.palette.primary.darkBlue,
  },
  avatarRootLarge: {
    width: "200px",
    height: "200px",
    fontSize: "4.875rem",
  },
  pageProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  centeredMessage: {
    position: "absolute",
    top: "35%",
    left: "35%",
    marginTop: -12,
    marginLeft: -12,
    [theme.breakpoints.down("md")]: {
      left: "25%",
    },
    [theme.breakpoints.down("xs")]: {
      left: "10%",
    },
  },

  //Buttons
  orangeButton: {
    backgroundColor: theme.palette.orange[200],
    color: theme.palette.white.main,
    border: "none",
    "&:hover": {
      backgroundColor: theme.palette.orange[300],
    },
    "&:active": {
      backgroundColor: theme.palette.orange[200],
    },
    "&:focus": {
      backgroundColor: theme.palette.orange[400],
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.white.main,
      color: theme.palette.primary.mediumBlue,
      borderColor: theme.palette.white.main,
    },
  },
  containedTertiaryButton: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.darkBlue,
    border: "1px solid " + theme.palette.primary.darkBlue,
    "&:hover": {
      backgroundColor: theme.palette.primary.greyBlue,
      color: theme.palette.white.main,
      borderColor: theme.palette.primary.greyBlue,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.darkBlue,
      color: theme.palette.white.main,
      borderColor: theme.palette.primary.darkBlue,
    },
    "&:focus": {
      color: theme.palette.primary.darkBlue,
      border: "2px solid " + theme.palette.secondary.skyBlue,
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.white.main,
      color: theme.palette.primary.mediumBlue,
      borderColor: theme.palette.primary.mediumBlue,
    },
  },
  containedTertiaryRedButton: {
    backgroundColor: theme.palette.white.main,
    color:  theme.palette.warning.main,
    border: "1px solid " +  theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.white.main,
      borderColor: theme.palette.warning.main,
    },
    "&:active": {
      backgroundColor:  theme.palette.warning.main,
      color: theme.palette.white.main,
      borderColor:  theme.palette.warning.main,
    },
    "&:focus": {
      color:  theme.palette.warning.main,
      border: "2px solid " + theme.palette.warning.main,
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.white.main,
      color: theme.palette.warning.tableLightHover,
      borderColor: theme.palette.warning.tableLightHover,
    },
  },
  containedGhostButton: {
    textDecorationLine: "none",
    textTransform: "uppercase",
    backgroundColor: theme.palette.transparent.main + "!important",
    color: theme.palette.primary.main,
    borderColor: theme.palette.transparent.main,
    boxShadow: "none !important",
    "&:hover": {
      color: theme.palette.primary.greyBlue,
      textDecorationLine: "none",
      fontWeight: "600",
    },
    "&:active": {
      color: theme.palette.primary.darkBlue,
      fontWeight: "600",
    },
    "&:focus": {
      color: theme.palette.primary.main,
      fontWeight: "600",
      "& .MuiButton-label": {
        textDecorationLine: "underline",
      },
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.mediumBlue,
    },
  },
  indicatorButton: {
    borderRadius: "4px",
    padding: "20px",
    width: "100%",
    textAlign: "left",
    textTransform: "none",
    boxShadow: "none",
    border: "none",
  },
  highlightedIndicatorButton: {
    height: "150px",
    background: "linear-gradient(180deg, rgba(159, 205, 250, 0.216) 0%, rgba(0, 88, 158, 0.464) 100%)",
    color: theme.palette.white.main,
    "& .MuiSvgIcon-root": {
      color: theme.palette.white.main,
    },
    "&:hover": {
      background: theme.palette.primary.greyBlue,
    },
    "&:active": {
      background: theme.palette.primary.darkBlue,
    },
  },
  normalIndicatorButton: {
    height: "115px",
    marginTop: "10px",
    background:
      "linear-gradient(180deg, " +
      theme.palette.white.main +
      " 17.19%, " +
      theme.palette.secondary.lightSkyBlue +
      " 75%, " +
      theme.palette.secondary.mediumSkyBlue +
      " 100%)",
    color: theme.palette.primary.darkBlue,
    fontWeight: "700",
    "&:hover": {
      background: theme.palette.primary.mediumBlue,
    },
    "&:active": {
      background: theme.palette.primary.main,
      color: theme.palette.white.main,
      "& .MuiSvgIcon-root": {
        color: theme.palette.white.main,
      },
    },
  },
  buttonProgress: {
    color: theme.palette.white.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  containerButtons: {
    marginLeft: "20px",
    marginRight: "20px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },

  //Containers
  containerRoot: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "3.5rem",
      paddingRight: "3.5rem",
    },
    paddingTop: "8.5rem",
    marginBottom: "2rem",
  },

  //Cards
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    borderRadius: "10px!important",
    padding: "1.25rem 2rem",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  cardHeader: {
    fontFamily: "PoppinsRegular",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "42px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  CardHeaderLink: {
    textTransform: "uppercase",
    color: theme.palette.white.main,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.mediumBlue,
    },
    marginRight: "10px",
  },
  TableCardHeaderButton: {
    marginTop: "22px",
    marginRight: "10px",
  },
  cardHeaderBig: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "27px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  cardHeaderAction: {
    display: "flex",
    marginTop: 0,
    marginRight: 0,
  },
  cardHeaderActionTypography: {
    marginBottom: "0px",
    marginTop: "8px",
    color: theme.palette.primary.greyBlue,
  },
  cardActionsRoot: {
    paddingBottom: "1.5rem!important",
    paddingTop: "1.5rem!important",
    borderTop: "0!important",
  },
  cardHeaderActionSwitchGrid: {
    paddingLeft: "5px",
    paddingRight: "5px",
    alignSelf: "center",
  },
  cardHeaderActionSwitchActiveLabel: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    paddingRight: "5px",
    paddingLeft: "5px",
  },
  cardHeaderActionSwitchInactiveLabel: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.gray[600],
    textTransform: "uppercase",
    paddingRight: "5px",
    paddingLeft: "5px",
  },
  cardElementsCount: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16.8px",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.gray[900],
    marginTop: "6px",
  },
  cardContentTitle: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
  },
  cardContentSubTitle: {
    fontSize: "14px",
    lineHeight: "27px",
  },
  cardHeaderTitleAlignedEnd: {
    textAlign: "end",
    alignSelf: "normal",
  },
  cardHeaderBorderless: {
    borderBottom: "0px!important",
  },
  cardActionBorderless: {
    borderTop: "0px!important",
    display: "block!important",
  },
  cardButtonFormGroup: {
    marginBottom: "0px",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  cardAvatarRoot: {
    width: "66px",
    height: "66px",
    marginRight: "0.5rem",
    fontSize: "21px",
    lineHeight: "29px",
    [theme.breakpoints.down("md")]: {
      marginRight: "0",
    },
  },
  cardExpand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  cardExpandOpen: {
    transform: "rotate(180deg)",
  },

  //Documents
  documentNameRoot: {
    paddingRight: "10px",
  },
  documentBadge: {
    backgroundColor: theme.palette.white.main,
    marginRight: "10px",
    marginBottom: "10px",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
    borderRadius: "4px",
    border: "1px solid " + theme.palette.primary.main,
    padding: ".4em .3em .3em .85em",
    alignItems: "center",
    wordBreak: "break-all",
  },
  documentBadgeContainer: {
    paddingLeft: "15px",
    overflow: "auto",
    maxHeight: "150px",
  },
  documentAvatar: {
    width: "100px",
    height: "100px",
  },
  documentDeleteButtonIconRoot: {
    color: theme.palette.primary.main,
    width: "20px",
    height: "20px",
  },
  documentDeleteButtonRoot: {
    padding: 0,
  },
  documentDropzone: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "1px",
    borderRadius: "1px",
    height: "62px!important",
    borderColor: theme.palette.gray[600],
    borderStyle: "dashed",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.gray[500],
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
  },

  //Forms
  formControlCheckboxLabelRoot: {
    position: "relative",
    display: "flex",
    minHeight: "1.5rem",
    WebkitPrintColorAdjust: "exact",
    marginLeft: "17px",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
  },
  formControlCheckboxLabelLabel: {
    cursor: "pointer",
    fontSize: ".875rem",
    position: "relative",
    verticalAlign: "top",
    display: "inline-block",
    color: theme.palette.gray[600],
  },
  formTextInputInTableCell: {
    marginBottom: "10px",
  },
  formRatingInTableCell: {
    marginBottom: "0px",
  },

  //Icons
  iconButtonOutlined: {
    padding: "2px",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.darkBlue,
    borderRadius: "28px",
    border: "1px solid " + theme.palette.primary.darkBlue,
    "&:hover": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.primary.greyBlue,
      borderColor: theme.palette.primary.greyBlue,
    },
    "&:active": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.primary.darkBlue,
      borderColor: theme.palette.primary.darkBlue,
    },
    "&:focus": {
      color: theme.palette.primary.darkBlue,
      borderColor: theme.palette.secondary.skyBlue,
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.mediumBlue,
      borderColor: theme.palette.primary.mediumBlue,
    },
  },
  overallRatingIcon: {
    marginLeft: "-10px",
    marginRight: "-10px",
  },
  timelineIcons: {
    color: theme.palette.white.main,
    width: "25px",
    height: "auto",
    marginTop: "5px",
  },
  timelineIconsBox: {
    background: theme.palette.primary.greyBlue,
    borderRadius: "35px",
    width: "37px",
    height: "37px",
    alignItems: "center",
    textAlign: "center",
  },
  pendingIcon: {
    color: theme.palette.warning.main,
    marginBottom: "2px",
    marginRight: "5px",
  },
  helpIconBox: {
    textAlign: "center",
    color: theme.palette.primary.mediumBlue,
  },
  helpIconRoot: {
    width: "100px",
    height: "auto",
  },
  cardHeaderinfoIcon: {
    color: theme.palette.primary.main,
    width: "30px",
    height: "auto",
  },
  alertIcon: {
    padding: "12px 0",
  },
  alertFilledWarning: {
    color: theme.palette.white.main,
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "19px",
    alignItems: "center",
  },
  tableErrorIcon: {
    color: theme.palette.error.main,
    width: "25px",
    height: "auto",
    margin: "0 auto",
    display: "block",
  },
  tableCheckIcon: {
    color: theme.palette.success.main,
    width: "25px",
    height: "auto",
    margin: "0 auto",
    display: "block",
  },
  socialMediaIcon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    borderRadius: "20px",
    padding: "5px",
    width: "25px",
    height: "25px",
    marginRight: "10px",
    cursor: "pointer",
  },
  expandMoreIcon: {
    height: "25px",
    width: "25px",
  },

  //Input Fields
  borderWarning: {
    borderColor: theme.palette.warning.main + "!important",
  },
  selectBorderWarningInputRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.warning.main + "!important",
    },
  },
  monetaryFields: {
    textAlign: "end",
  },

  //Labels
  detailLabels: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  detailFormLabels: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  pageLabels: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "31px",
    textAlign: "justify",
    marginBottom: "30px",
  },
  pageLabelsWithoutMargin: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "31px",
    textAlign: "justify",
  },

  //Pagination
  alignPagination: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
      marginTop: "20px",
      marginBottom: "20px",
    },
  },

  //Tables
  tableCardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    borderRadius: "10px!important",
  },
  tableCardHeaderRoot: {
    backgroundColor: theme.palette.primary.darkBlue,
  },
  tableCardHeaderTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    color: theme.palette.white.main,
  },
  tableCardHeaderIcon: {
    width: "auto",
    height: "50px",
    marginBottom: "10px",
    marginRight: "10px",
  },
  tableCardElementsCount: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "40px",
    lineHeight: "60px",
    alignItems: "left",
    textAlign: "left",
    color: theme.palette.white.main,
    marginTop: "6px",
  },
  tableCellRoot: {
    verticalAlign: "middle",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    borderTop: "0",
    fontSize: "14px",
    padding: ".25rem",
    fontWeight: "500",
    lineHeight: "17px",
  },
  tableCellRootHead: {
    backgroundColor: theme.palette.orange[100],
    color: theme.palette.gray[900],
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "none !important",
    fontStyle: "normal",
  },
  tableCellHeadStickyRight: {
    textAlign: "center",
    position: "sticky",
    right: 0,
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    boxShadow: "inset 2px 0" + theme.palette.gray[200],
  },
  tableCellBodyStickyRight: {
    position: "sticky",
    right: 0,
    backgroundColor: theme.palette.white.main,
    textAlign: "center",
    boxShadow: "inset 2px 0" + theme.palette.gray[200],
  },
  tableRowEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "200px",
  },
  noResultsInfo: {
    fontStyle: "normal",
    fontWeight: "200",
    fontSize: "20px",
    lineHeight: "40px",
    alignItems: "center",
    color: theme.palette.primary.main,
    marginLeft: "50px",
    marginBottom: "20px",
  },

  //Tabs
  changeTabsGroup: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(min(310px, 100%), 1fr))",
    flex: 1,
    margin: "0 15px",
    marginBottom: "25px",
  },
  changeTabs: {
    width: "100%",
    heigth: "40px",
    backgroundColor: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);",
    borderRadius: "10px",
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16.8px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.darkBlue,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.darkBlue,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.02em",
      color: theme.palette.white.main,
      "&:hover": {
        background: theme.palette.primary.darkBlue,
      },
    },
    "&:hover": {
      background: theme.palette.primary.greyBlue,
      color: theme.palette.white.main,
    },
  },

  //Tooltips
  tooltipInfoIcon: {
    color: theme.palette.primary.main,
    marginLeft: "5px",
    marginTop: "-3px",
  },
  tooltip: {
    maxWidth: "280px",
    marginTop: "1px",
    marginBottom: "1px",
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.black.light,
    textAlign: "justify",
  },
  tooltipArrow: {
    color: theme.palette.grey[300],
  },
  tooltipDescritionSizeWidth: {
    maxWidth: 500,
  },

  //Steppers
  stepperRoot: {
    "& .MuiStepIcon-root": {
      width: "25px",
      height: "25px",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: theme.palette.primary.greyBlue,
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: theme.palette.primary.darkBlue,
    },

    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: theme.palette.primary.greyBlue,
    },
    "& .MuiStepLabel-alternativeLabel": {
      color: theme.palette.primary.darkBlue,
    },
    color: theme.palette.white.main,
    backgroundColor: theme.palette.transparent.main,
  },
  stepperBox: {
    background:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
  },
  stepperText: {
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "13px",
      lineHeight: "18px",
      color: theme.palette.white.main,
      fontFamily: "PoppinsRegular",
    },
    "& .MuiStepLabel-label": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      color: theme.palette.white.main,
      fontFamily: "PoppinsRegular",
    },
  },
  stepperCircle: {
    fill: theme.palette.white.main + "solid",
  },
  stepperFilledCircle: {
    fill: theme.palette.primary.greyBlue,
  },
  stepperCheck: {
    fill: theme.palette.primary.greyBlue,
    width: "30px",
    height: "30px",
  },

  // This block was left commented on purpose to prevent console errors.
  // This is being commented on 01/06/2023. If enough time has passed and no issues
  // arose, this block may be deleted.

  /* //Switches
  warningSwitchThumb: {},
  warningSwitchTrack: {},
  disabledSwitchThumb: {},
  disabledSwitchTrack: {},
  checkedSwitch: {
    "& $disabledSwitchThumb": {
      backgroundColor: theme.palette.gray[200] + "!important",
    },
    "& + $disabledSwitchTrack": {
      borderColor: theme.palette.gray[200] + "!important",
    },
    "& $infoSwitchThumb": {
      backgroundColor: theme.palette.info.main + "!important",
    },
    "& + $infoSwitchTrack": {
      borderColor: theme.palette.info.main + "!important",
    },
    "& $successSwitchThumb": {
      backgroundColor: theme.palette.success.main + "!important",
    },
    "& + $successSwitchTrack": {
      borderColor: theme.palette.success.main + "!important",
    },
    "& $errorSwitchThumb": {
      backgroundColor: theme.palette.error.main + "!important",
    },
    "& + $errorSwitchTrack": {
      borderColor: theme.palette.error.main + "!important",
    },
    "& $warningSwitchThumb": {
      backgroundColor: theme.palette.primary.main + "!important",
    },
    "& + $warningSwitchTrack": {
      borderColor: theme.palette.primary.main + "!important",
    },
    "& $whiteSwitchThumb": {
      backgroundColor: theme.palette.white.main + "!important",
    },
    "& + $whiteSwitchTrack": {
      borderColor: theme.palette.white.main + "!important",
    },
  }, */

  // Modals
  modalHeader: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: "65px",
  },
  modalSubHeader: {
    fontStyle: "normal",
    fontSize: "1rem",
    alignItems: "center",
    textAlign: "center",
    marginTop: "-40px",
  },
  modalTitles: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.gray[900],
    display: "flex",
    alignItems: "center",
  },
  modalHelpIcon: {
    color: theme.palette.primary.mediumBlue,
    width: "120px",
    height: "120px",
    boxSizing: "border-box",
    margin: "0 auto",
    display: "block",
  },

  //IFrames
  iframeBoxContainerRoot: {
    position: "absolute",
    left: "0",
    right: "0",
    bottom: "0",
    top: "0px",
    paddingTop: "8.5rem",
    marginBottom: "2rem",
  },
});

export default componentStyles;
