import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const CREATE_OPPORTUNITY_URL = "opportunity/create";
export const CREATE_OPPORTUNITY_CANDIDATE_URL = "opportunity/create-opportunity-candidate";
export const UPDATE_OPPORTUNITY_URL = "opportunity/update";
export const GET_FILTERED_OPPORTUNITY_URL = "opportunity/get-filtered-opportunities";
export const GET_FILTERED_AVAILABLE_OPPORTUNITY_URL = "opportunity/get-filtered-available-opportunities";
export const GET_RECOMMENDED_OPPORTUNITY_URL = "opportunity/get-recommended-opportunities";
export const GET_OPPORTUNITIES_DETAILS_URL = "opportunity/get-opportunity-details";
export const POST_UPDATE_OPPORTUNITY_STATE_URL = "opportunity/update-opportunity-state";
export const GET_USER_INFO_URL = "opportunity/get-opportunity-user-info";
export const GET_OPPORTUNITY_USERS_URL = "opportunity/get-opportunity-users";
export const APPROVE_CANDIDATE_URL = "opportunity/approve-opportunity-candidate";
export const REJECT_CANDIDATE_URL = "opportunity/reject-opportunity-candidate";
export const CANDIDATE_WITHDRAW_URL = "opportunity/opportunity-candidate-withdraw";
export const GET_OPPORTUNITY_DOCUMENT = "opportunity/get-opportunity-document";
export const GET_POSSIBLE_OPPORTUNITY_CANDIDATES = "opportunity/get-possible-opportunity-candidates";
export const ADD_OPPORTUNITY_CANDIDATES_LIST = "opportunity/add-opportunity-candidates-list";
export const GET_MY_OPPORTUNITY_APPLICATIONS_URL = "opportunity/get-my-opportunity-applications";
export const GET_OPPORTUNITY_APPLICATION_DETAILS_URL = "opportunity/get-opportunity-application-details";
export const GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS_URL = "opportunity/get-user-colective-opportunity-applications";
export const GET_MY_OPPORTUNITY_APPLICATIONS_FOLLOWUP_URL = "opportunity/get-my-opportunity-applications-followup";
export const GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_URL = "opportunity/get-opportunity-application-followup-details";
export const UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD = "opportunity/update-opportunity-application-followup-period";
export const GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_FEEDBACK_REPORT_URL = "opportunity/get-opportunity-application-followup-feedback-report-details";
export const UPDATE_OPPORTUNITY_FOLLOWUP_FEEDACK_REPORT = "opportunity/update-opportunity-application-followup-feedback-report";

export function createOpportunity(opportunity) {
  return axios.post(EXTERNAL_API_URL + CREATE_OPPORTUNITY_URL, opportunity);
}

export function createOpportunityCandidate(opportunity) {
  return axios.post(EXTERNAL_API_URL + CREATE_OPPORTUNITY_CANDIDATE_URL, opportunity);
}

export function updateOpportunity(opportunity) {
  return axios.post(EXTERNAL_API_URL + UPDATE_OPPORTUNITY_URL, opportunity);
}

export function getFilteredOpportunities(data) {
  return axios.get(EXTERNAL_API_URL + GET_FILTERED_OPPORTUNITY_URL, {
    params: data,
  });
}

export function getFilteredAvailableOpportunities(data) {
  return axios.get(EXTERNAL_API_URL + GET_FILTERED_AVAILABLE_OPPORTUNITY_URL, {
    params: data,
  });
}

export function getRecommendedOpportunities(data) {
  return axios.get(EXTERNAL_API_URL + GET_RECOMMENDED_OPPORTUNITY_URL, {
    params: data,
  });
}

export function getOpportunitiesDetails(_opportunityId) {
  return axios.get(EXTERNAL_API_URL + GET_OPPORTUNITIES_DETAILS_URL, {
    params: {
      opportunityId: _opportunityId,
    },
  });
}

export function updateOpportunityState(formData) {
  return axios.post(EXTERNAL_API_URL + POST_UPDATE_OPPORTUNITY_STATE_URL, formData);
}

//TO-DO Este metódo tem que ser mudado para receber o Id do oportunity e devolver a user info + estado da candidatura + (etc)
export function getCandidateInformation(userId) {
  return axios.get(EXTERNAL_API_URL + GET_USER_INFO_URL, {
    params: {
      alternateKey: userId,
    },
  });
}

export function getOpportunityUsers(formData) {
  return axios.get(EXTERNAL_API_URL + GET_OPPORTUNITY_USERS_URL, {
    params: formData,
  });
}

export function approveOpportunityCandidate(formData) {
  return axios.post(EXTERNAL_API_URL + APPROVE_CANDIDATE_URL, formData);
}
export function rejectOpportunityCandidate(formData) {
  return axios.post(EXTERNAL_API_URL + REJECT_CANDIDATE_URL, formData);
}

export function opportunityCandidateWithdraw(formData) {
  return axios.post(EXTERNAL_API_URL + CANDIDATE_WITHDRAW_URL, formData);
}

export function getOpportunityDocument(data) {
  return axios.get(EXTERNAL_API_URL + GET_OPPORTUNITY_DOCUMENT, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      docId: data,
    },
    responseType: "blob",
  });
}

export function getFilteredPossibleOpportunityCandidates(data) {
  return axios.get(EXTERNAL_API_URL + GET_POSSIBLE_OPPORTUNITY_CANDIDATES, {
    params: data,
  });
}

export function addOpportunityCandidatesList(formData) {
  return axios.post(EXTERNAL_API_URL + ADD_OPPORTUNITY_CANDIDATES_LIST, formData);
}

export function getMyOpportunityApplications(formData) {
  return axios.get(EXTERNAL_API_URL + GET_MY_OPPORTUNITY_APPLICATIONS_URL, {
    params: formData,
  });
}

export function getOpportunityApplicationDetails(_opportunityApplicationCode) {
  return axios.get(EXTERNAL_API_URL + GET_OPPORTUNITY_APPLICATION_DETAILS_URL, {
    params: {
      opportunityApplicationCode: _opportunityApplicationCode,
    },
  });
}

export function getUserColectiveOpportunityApplications(formData) {
  return axios.get(EXTERNAL_API_URL + GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS_URL, {
    params: formData,
  });
}

export function getMyOpportunityApplicationsFollowUp(formData) {
  return axios.get(EXTERNAL_API_URL + GET_MY_OPPORTUNITY_APPLICATIONS_FOLLOWUP_URL, {
    params: formData,
  });
}

export function getOpportunityApplicationsFollowUpDetails(candidateCode) {
  return axios.get(EXTERNAL_API_URL + GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_URL, {
    params: {
      opportunityCandidateId: candidateCode,
    },
  });
}

export function updateOpportunityFollowupPeriod(formData) {
  return axios.post(EXTERNAL_API_URL + UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD, formData);
}

export function getOpportunityApplicationsFollowUpFeedbackReport(feedbackReportId) {
  return axios.get(EXTERNAL_API_URL + GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_FEEDBACK_REPORT_URL, {
    params: {
      feedbackReportId: feedbackReportId,
    },
  });
}

export function updateOpportunityFollowupFeedbackReport(formData) {
  return axios.post(EXTERNAL_API_URL + UPDATE_OPPORTUNITY_FOLLOWUP_FEEDACK_REPORT, formData);
}
