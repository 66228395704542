import {
  createOpportunity,
  getFilteredOpportunities,
  getFilteredAvailableOpportunities,
  getRecommendedOpportunities,
  getOpportunitiesDetails,
  updateOpportunityState,
  getCandidateInformation,
  getOpportunityUsers,
  updateOpportunity,
  rejectOpportunityCandidate,
  approveOpportunityCandidate,
  opportunityCandidateWithdraw,
  createOpportunityCandidate,
  getOpportunityDocument,
  getFilteredPossibleOpportunityCandidates,
  addOpportunityCandidatesList,
  getMyOpportunityApplications,
  getOpportunityApplicationDetails,
  getUserColectiveOpportunityApplications,
  getMyOpportunityApplicationsFollowUp,
  getOpportunityApplicationsFollowUpDetails,
  updateOpportunityFollowupPeriod,
  getOpportunityApplicationsFollowUpFeedbackReport,
  updateOpportunityFollowupFeedbackReport,
} from "crud/opportunities.crud";

import { OPPORTUNITIES_ACTIONS, DIALOG } from "utils/actionsConsts";
import { UPDATE_OPPORTUNITY_STATE_ACTION_TYPE } from "utils/const";

import { b64ToImage, convertByteArrayToBlobUrl } from "utils/converters";

import moment from "moment";
import { get } from "lodash";
import { takeResponseErrors } from "utils/takeResponseErrors";
import { INTERNSHIP_TYPE_KEY } from "utils/const";

export const createOpportunityAction = (data) => async (dispatch) => {
  const internshipTypeId = data.get("opportunity.internshipTypeId");

  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_SUBMIT_LOADING,
    payload: true,
  });
  createOpportunity(data)
    .then(() => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.CREATE,
        payload: true,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_SUBMIT_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: true,
            goBack: true,
            buttonLabel: "Ok",
            onClose: "/admin/opportunities",
            message:
              !internshipTypeId || internshipTypeId === INTERNSHIP_TYPE_KEY.GERAL
                ? "Oferta criada com sucesso."
                : "Oferta submetida para aprovação.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.CREATE,
        payload: false,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_SUBMIT_LOADING,
        payload: false,
      });

      if (get(error, "response.status") === 400) {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: takeResponseErrors(error?.response.data?.errors),
            },
          },
        });
      } else {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
            },
          },
        });
      }
    });
};

export const createOpportunityCandidateAction = (data) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_SUBMIT_OPPORTUNITY_CANDIDATE_LOADING,
    payload: true,
  });
  createOpportunityCandidate(data)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.CREATE_OPPORTUNITY_CANDIDATES,
        payload: true,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_SUBMIT_OPPORTUNITY_CANDIDATE_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data.success,
            goBack: true,
            buttonLabel: "ACOMPANHAR CANDIDATURA",
            onCloseAction: () => {
              dispatch({
                type: OPPORTUNITIES_ACTIONS.CLEAR_OPPORTUNITY_DETAILS_ACTIONS,
              });
            },
            okAction: () => {
              dispatch({
                type: OPPORTUNITIES_ACTIONS.CLEAR_OPPORTUNITY_DETAILS_ACTIONS,
              });
            },
            onClose: res.data.success ? "/search/opportunity-applications" : undefined,
            message: res.data.success
              ? "Candidatura submetida para aprovação. Nº de Candidatura - " + res.data.opportunityCandidateCode
              : "Ocorreu um erro a submeter a candidatura, por favor tente mais tarde",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.CREATE_OPPORTUNITY_CANDIDATES,
        payload: false,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_SUBMIT_OPPORTUNITY_CANDIDATE_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            noRedirect: true,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
    });
};

export const updateOpportunityAction = (data) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_UPDATE_LOADING,
    payload: true,
  });
  updateOpportunity(data)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE,
        payload: true,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_UPDATE_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data ? true : false,
            goBack: true,
            buttonLabel: "Ok",
            onClose: "/admin/opportunities",
            message: res.data ? "Oferta actualizada com sucesso." : "Ocorreu um erro ao actualizar Oferta.",
          },
        },
      });
    })
    .catch(() => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE,
        payload: false,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_UPDATE_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
    });
};

export const getFilteredOpportunitiesAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS_LOADING,
    payload: true,
  });
  getFilteredOpportunities(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.id,
          userId: record.userId,
          userName: record.userName,
          numVacancies: record.numVacancies,
          numVacanciesToCandidatesRatio: record.approvedCandidates + "/" + record.numVacancies,
          provinceId: record.provinceId,
          provinceKey: record.provinceKey,
          provinceDescription: record.provinceDescription,
          reference: record.reference,
          title: record.title,
          validFrom: record.validFrom,
          validTo: record.validTo,
          validPeriod:
            record.validFrom !== null && record.validTo !== null
              ? moment(record.validFrom).format("DD/MM/YYYY") + " - " + moment(record.validTo).format("DD/MM/YYYY")
              : "N/A",
          opportunityStateId: record.opportunityStateId,
          opportunityStateKey: record.opportunityStateKey,
          opportunityStateDescription: record.opportunityStateDescription,
          opportunityTypeId: record.opportunityTypeId,
          opportunityTypeKey: record.opportunityTypeKey,
          opportunityTypeDescription: record.opportunityTypeDescription,
          totalCandidates: record.totalCandidates,
          contractTypeId: record.contractTypeId,
          contractTypeKey: record.contractTypeKeyValue,
          contractTypeDescription: record.contractTypeDescription,
          internshipTypeId: record.internshipTypeId,
          internshipTypeKey: record.internshipTypeKeyValue,
          internshipTypeDescription: record.internshipTypeDescription,
          professionalCategoriesDescription: record.professionalCategoriesDescription,
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getFilteredAvailableOpportunitiesAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_FILTERED__AVAILABLE_OPPORTUNITIES_ACTIONS_LOADING,
    payload: true,
  });
  getFilteredAvailableOpportunities(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.id,
          userName: record.userName,
          userPhoto: b64ToImage(record.userPhoto),
          provinceDescription: record.provinceDescription,
          title: record.title,
          createdOn: moment(record.createdOn),
          opportunityTypeDescription: record.opportunityTypeDescription,
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_AVAILABLE_OPPORTUNITIES_ACTIONS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED__AVAILABLE_OPPORTUNITIES_ACTIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_AVAILABLE_OPPORTUNITIES_ACTIONS,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED__AVAILABLE_OPPORTUNITIES_ACTIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getRecommendedOpportunitiesAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_RECOMMENDED_OPPORTUNITIES_ACTIONS_LOADING,
    payload: true,
  });
  getRecommendedOpportunities(formData)
    .then((res) => {
      const mappedList = res.data?.map((record) => {
        return {
          id: record.id,
          userName: record.userName,
          userPhoto: b64ToImage(record.userPhoto),
          provinceDescription: record.provinceDescription,
          title: record.title,
          createdOn: moment(record.createdOn),
          opportunityTypeDescription: record.opportunityTypeDescription,
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_RECOMMENDED_OPPORTUNITIES_ACTIONS,
        result: true,
        payload: {
          data: mappedList,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_RECOMMENDED_OPPORTUNITIES_ACTIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_RECOMMENDED_OPPORTUNITIES_ACTIONS,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_RECOMMENDED_OPPORTUNITIES_ACTIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getOpportunitiesDetailsAction = (opportunityId) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.CLEAR_OPPORTUNITY_DETAILS_ACTIONS,
  });
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS_LOADING,
    payload: true,
  });
  getOpportunitiesDetails(opportunityId)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS,
        result: true,
        payload: {
          ...res.data,
          userPhoto: b64ToImage(res.data?.userPhoto),
          validPeriod:
            res.data?.validFrom !== null && res.data?.validTo !== null
              ? `De ${moment(res.data.validFrom).format("DD/MM/YYYY")} a ${moment(res.data.validTo).format(
                  "DD/MM/YYYY"
                )}`
              : undefined,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS,
        result: false,
        payload: {},
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateOpportunityStateAction = (formData, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_STATE_ACTIONS_LOADING,
    payload: true,
  });
  updateOpportunityState(formData)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_STATE_ACTIONS_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data ? _onCloseAction : undefined,
            okAction: res.data ? _onCloseAction : undefined,
            isSuccess: res.data,
            message: res.data
              ? getSuccessMessage(formData.actionType)
              : "Ocorreu um erro ao actualizar o estado da oferta.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao actualizar o estado da oferta.",
          },
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_STATE_ACTIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getCandidateInformationAction = (userId) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_CANDIDATE_INFORMATION_LOADING,
    payload: true,
  });
  getCandidateInformation(userId)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_CANDIDATE_INFORMATION,
        payload: res.data,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_CANDIDATE_INFORMATION_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_CANDIDATE_INFORMATION_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export const getOpportunityUsersAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS_LOADING,
    payload: true,
  });
  getOpportunityUsers(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          userId: record.userId,
          alternateKey: record.alternateKey,
          opportunityId: record.opportunityId,
          userName: record.userName,
          identityCard: record.userIdentityCard,
          candidateState: record.opportunityCandidateStateId,
          candidateStateDescription: record.opportunityCandidateStateDescription,
          applicationDate:
            record.opportunityCandidateDate !== null
              ? moment(record.opportunityCandidateDate).format("DD/MM/YYYY")
              : "N/A",
          papeInternshipOverlapsOthersOfUser: record.papeInternshipOverlapsOthersOfUser,
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const approveOpportunityCandidateAction = (formData, isOpportunityApplication) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.APPROVE_CANDIDATE_LOADING,
    payload: true,
  });
  approveOpportunityCandidate(formData)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onClose: isOpportunityApplication
              ? "/admin/user-colective-opportunity-applications"
              : "/admin/opportunities",
            goBack: true,
            isSuccess: res.data ? true : false,
            message: res.data ? "Candidato recrutado!" : "Ocorreu um erro ao recrutar candidato.",
          },
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.APPROVE_CANDIDATE_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.APPROVE_CANDIDATE_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      console.log("error", error);
    });
};

export const rejectOpportunityCandidateAction = (formData, isOpportunityApplication) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.REJECT_CANDIDATE_LOADING,
    payload: true,
  });
  rejectOpportunityCandidate(formData)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onClose: isOpportunityApplication
              ? "/admin/user-colective-opportunity-applications"
              : "/admin/opportunities",
            goBack: true,
            isSuccess: res.data ? true : false,
            message: res.data ? "Candidato rejeitado!" : "Ocorreu um erro ao rejeitar candidato.",
          },
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.REJECT_CANDIDATE_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      console.log("error", error);
    });
};

export const opportunityCandidateWithdrawAction =
  (formData, isOpportunityApplication, toWithdraw) => async (dispatch) => {
    dispatch({
      type: OPPORTUNITIES_ACTIONS.CANDIDATE_WITHDRAW_LOADING,
      payload: true,
    });
    opportunityCandidateWithdraw(formData)
      .then((res) => {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              onClose: isOpportunityApplication
                ? "/admin/user-colective-opportunity-applications"
                : "/admin/opportunities",
              goBack: true,
              isSuccess: !!res.data,
              message: res.data
                ? toWithdraw
                  ? "Registada a desistência do candidato."
                  : "Anulada a desistência do candidato."
                : "Ocorreu um erro ao registar a desistência do candidato.",
            },
          },
        });
        dispatch({
          type: OPPORTUNITIES_ACTIONS.CANDIDATE_WITHDRAW_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: error.response?.data?.errors
                ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
                : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
            },
          },
        });
        console.log("error", error);
      });
  };

export const getOpportunityDocumentAction = (docId, docName) => async (dispatch) => {
  getOpportunityDocument(docId)
    .then((res) => {
      const link = document.createElement("a");
      link.href = convertByteArrayToBlobUrl(res.data);
      link.setAttribute("download", docName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DOCUMENT,
        result: false,
        payload: null,
      });

      console.log("error", error);
    });
};

const getSuccessMessage = (actionType) => {
  switch (actionType) {
    case UPDATE_OPPORTUNITY_STATE_ACTION_TYPE.REJECT:
      return "Oportunidade rejeitada com sucesso";
    case UPDATE_OPPORTUNITY_STATE_ACTION_TYPE.CLOSE:
      return "Oportunidade fechada com sucesso";
    case UPDATE_OPPORTUNITY_STATE_ACTION_TYPE.APPROVE:
      return "Oportunidade aprovada com sucesso";
    default:
      return "Oportunidade actualizada com sucesso";
  }
};

export const getFilteredPossibleOpportunityCandidatesAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES_LOADING,
    payload: true,
  });
  getFilteredPossibleOpportunityCandidates(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          userId: record.userId,
          userName: record.userName,
          userIdentityCard: record.userIdentityCard,
          province: record.province,
          municipality: record.municipality,
          intendedWorkplaceProvince: record.intendedWorkplaceProvince,
          academicLevel: record.academicLevel,
          professionalCategories: record.professionalCategories,
          studiedLanguages: record.studiedLanguages,
          isAvailableForInternshipOpportunities: record.isAvailableForInternshipOpportunities,
          isAvailableForEmploymentOpportunities: record.isAvailableForEmploymentOpportunities,
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const addOpportunityCandidatesListAction = (data, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.ADD_OPPORTUNITY_CANDIDATES_LIST_LOADING,
    payload: true,
  });
  addOpportunityCandidatesList(data)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.ADD_OPPORTUNITY_CANDIDATES_LIST_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data.success,
            buttonLabel: "Ok",
            okAction: res.data.success ? _onCloseAction : undefined,
            onCloseAction: res.data.success ? _onCloseAction : undefined,
            message: res.data.success
              ? `Foram adicionados ${res.data.addedCandidatesCount} candidatos com sucesso à oferta`
              : "Ocorreu um erro ao adicionar candidatos à oferta.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.ADD_OPPORTUNITY_CANDIDATES_LIST_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const getMyOpportunityApplicationsAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS_LOADING,
    payload: true,
  });
  getMyOpportunityApplications(formData)
    .then((res) => {
      const mappedList = res?.data?.data?.map((record) => {
        return {
          candidateCode: record.candidateCode,
          opportunityId: record.opportunityId,
          opportunityTitle: record.opportunityTitle,
          candidateStateId: record.candidateStateId,
          candidateStateKey: record.candidateStateKey,
          candidateStateDescription: record.candidateStateDescription,
          opportunityTypeId: record.opportunityTypeId,
          opportunityTypeKey: record.opportunityTypeKey,
          opportunityTypeDescription: record.opportunityTypeDescription,
          companyId: record.companyId,
          companyName: record.companyName,
          companyTaxIdNumber: record.companyTaxIdNumber,
          creationDate: moment(record.creationDate).format("DD/MM/YYYY"),
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getOpportunityApplicationDetailsAction = (opportunityId) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.CLEAR_OPPORTUNITY_APPLICATION_DETAILS_ACTIONS,
  });
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATION_DETAILS_ACTIONS_LOADING,
    payload: true,
  });
  getOpportunityApplicationDetails(opportunityId)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATION_DETAILS_ACTIONS,
        result: true,
        payload: {
          //dados da candidatura
          applicationCode: res.data?.applicationCode,
          ApplicationStateId: res.data?.applicationStateId,
          ApplicationStateKey: res.data?.applicationStateKey,
          ApplicationStateDescription: res.data?.applicationStateDescription,
          //dados empresa
          userPhoto: b64ToImage(res.data?.companyDetails?.userPhoto),
          userName: res.data?.companyDetails?.companyName,
          userProvince: res.data?.companyDetails?.provinceDescription,
          userMunicipality: res.data?.companyDetails?.municipalityDescription,
          userNIF: res.data?.companyDetails?.taxIdNumber,
          userBusinessPurpose: res.data?.companyDetails?.businessPurposeDescriptionList,
          //dados oferta
          opportunityTypeKey: res.data?.opportunityDetails?.opportunityTypeKey,
          title: res.data?.opportunityDetails?.title,
          provinceDescription: res.data?.opportunityDetails?.provinceDescription,
          createdOn: res.data?.opportunityDetails?.publishDate,
          description: res.data?.opportunityDetails?.description,
          opportunityTypeDescription: res.data?.opportunityDetails?.opportunityTypeDescription,
          timetableTypeDescription: res.data?.opportunityDetails?.opportunityTypeDescription,
          opportunityProfessionalCategoryDescription: res.data?.opportunityDetails?.professionalCategoryDescription,
          userAlreadyApplied: true,
          opportunityQuestions: res.data?.opportunityQuestionsAnswers,
          validPeriod:
            res.data?.validFrom !== null && res.data?.validTo !== null
              ? `De ${moment(res.data.validFrom).format("DD/MM/YYYY")} a ${moment(res.data.validTo).format(
                  "DD/MM/YYYY"
                )}`
              : undefined,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATION_DETAILS_ACTIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATION_DETAILS_ACTIONS,
        result: false,
        payload: {},
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATION_DETAILS_ACTIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getUserColectiveOpportunityApplicationsAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS_LOADING,
    payload: true,
  });
  getUserColectiveOpportunityApplications(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          opportunityId: record.opportunityId,
          opportunityTypeId: record.opportunityTypeId,
          opportunityTypeKey: record.opportunityTypeKey,
          opportunityTypeDescription: record.opportunityTypeDescription,
          opportunityTitle: record.opportunityTitle,
          opportunityReference: record.opportunityReference,
          candidateCode: record.candidateCode,
          candidateStateId: record.candidateStateId,
          candidateStateKey: record.candidateStateKey,
          candidateStateDescription: record.candidateStateDescription,
          userId: record.userId,
          userName: record.userName,
          userIdentityCard: record.userIdentityCard,
          creationDate: moment(record.creationDate).format("DD/MM/YYYY"),
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getIsOpportunityApplicationAction = (isOpportunityApplication) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_IS_OPPORTUNITY_APPLICATION,
    payload: isOpportunityApplication,
  });
};

export const getMyOpportunityApplicationsFollowUpAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS_FOLLOWUP_LOADING,
    payload: true,
  });
  getMyOpportunityApplicationsFollowUp(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          // For User Singular
          companyId: record.companyId,
          companyName: record.companyName,
          companyTaxIdNumber: record.companyTaxIdNumber,
          // For User Colective
          userId: record.userId,
          userName: record.userName,
          userIdentityCard: record.userIdentityCard,
          opportunityReference: record.opportunityReference,
          // Common
          opportunityId: record.opportunityId,
          opportunityTitle: record.opportunityTitle,
          opportunityTypeId: record.opportunityTypeId,
          opportunityTypeKey: record.opportunityTypeKey,
          opportunityTypeDescription: record.opportunityTypeDescription,
          candidateCode: record.candidateCode,
          candidateFollowUpStateId: record.candidateFollowUpStateId,
          candidateFollowUpStateKey: record.candidateFollowUpStateKey,
          candidateFollowUpStateDescription: record.candidateFollowUpStateDescription,
          creationDate: record.creationDate,
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS_FOLLOWUP,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS_FOLLOWUP_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS_FOLLOWUP,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS_FOLLOWUP_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getOpportunityApplicationsFollowUpDetailsAction = (candidateCode) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_LOADING,
    payload: true,
  });
  getOpportunityApplicationsFollowUpDetails(candidateCode)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS,
        payload: res.data,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export const updateOpportunityFollowupPeriodAction = (formData, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD_LOADING,
    payload: true,
  });
  updateOpportunityFollowupPeriod(formData)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data ? _onCloseAction : undefined,
            okAction: res.data ? _onCloseAction : undefined,
            isSuccess: res.data,
            message: res.data
              ? "Estágio atualizado com sucesso"
              : "Ocorreu um erro ao actualizar as datas de início/fim do estágio.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao actualizar as datas de início/fim do estágio.",
          },
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getFeedbackReportDetailsAction = (feedbackReportId) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_FEEDBACK_REPORT_DETAILS_LOADING,
    payload: true,
  });
  getOpportunityApplicationsFollowUpFeedbackReport(feedbackReportId)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FEEDBACK_REPORT_DETAILS,
        payload: res.data,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FEEDBACK_REPORT_DETAILS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FEEDBACK_REPORT_DETAILS_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export const updateFeedbackReportAction = (formData, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.UPDATE_FEEDBACK_REPORT_LOADING,
    payload: true,
  });

  updateOpportunityFollowupFeedbackReport(formData)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE_FEEDBACK_REPORT_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data ? _onCloseAction : undefined,
            okAction: res.data ? _onCloseAction : undefined,
            isSuccess: res.data,
            message: res.data ? "Formulário submetido com sucesso" : "Ocorreu um erro ao submeter o formulário.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao submeter o formulário.",
          },
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE_FEEDBACK_REPORT_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export default createOpportunityAction;
