import { combineReducers } from "redux";
import authReducer from "redux/reducers/authReducer";
import datasetsReducer from "redux/reducers/datasetsReducer";
import dialogReducer from "redux/reducers/dialogsReducer";
import applicationsReducer from "redux/reducers/applicationsReducer";
import notificationsReducer from "redux/reducers/notificationsReducer";
import personalProfileReducer from "redux/reducers/personalProfileReducer";
import informationDisseminationReducer from "redux/reducers/informationDisseminationReducer";
import OpportunitiesReducer from "redux/reducers/opportunitiesReducer";
import governmentProgramsReducer from "redux/reducers/governmentProgramsReducer";
import dashboardReducer from "redux/reducers/dashboardReducer";
import ssoReducer from "redux/reducers/ssoReducer";
import applicationConfigurationReducer from "./applicationConfigurationReducer";
import socialMediaReducer from "redux/reducers/socialMediaReducer";
import statisticalDataReducer from "redux/reducers/statisticalDataReducer";
import newsletterReducer from "redux/reducers/newsletterReducer";
import reportsReducer from "redux/reducers/reportsReducer";

const allReducers = combineReducers({
  authState: authReducer,
  datasetsState: datasetsReducer,
  dialogState: dialogReducer,
  applicationsState: applicationsReducer,
  notificationState: notificationsReducer,
  personalProfileState: personalProfileReducer,
  informationDisseminationState: informationDisseminationReducer,
  opportunitiesState: OpportunitiesReducer,
  governmentProgramsState: governmentProgramsReducer,
  dashboardState: dashboardReducer,
  ssoState: ssoReducer,
  applicationConfigurationState: applicationConfigurationReducer,
  socialMediaState: socialMediaReducer,
  statisticalDataState: statisticalDataReducer,
  newsletterState: newsletterReducer,
  reportState: reportsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "logout") {
    const { authState } = state;
    authState.operationsList = [];
    authState.login_error = false;
    authState.login_message = null;
    authState.photo = null;
    authState.personalInformation = null;
    state = { authState };

    return allReducers(state, action);
  }

  return allReducers(state, action);
};

export default rootReducer;
