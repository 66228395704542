import React from "react";
import { connect } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

// @material-ui/core components
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Card, CardContent, CardHeader, FilledInput } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "@material-ui/core/Tooltip";

import HelpIcon from "@material-ui/icons/Help";

//custom components
import FormMultipleSelect from "components/FormFields/FormMultipleSelect";
import FormInput from "components/Forms/FormInput";
import DropzoneField from "components/FormFields/DropzoneField";
import SubmitedDocumentsSection from "components/FormFields/SubmitedDocumentsSection";
import componentStylesUserProfile from "assets/theme/views/admin/user-profile.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { messages, labels, errorMessages } from "resources/resources";
import { DATASET } from "utils/const";
import FormSelect from "components/FormFields/FormSelect";
import GenericButton from "components/Buttons/GenericButton";
import { getDatasetStateByName } from "utils/datasets";
import { getDataSetByNameAction, getChildrenByPropertyIdAction } from "redux/actions/datasetsAction";
import { getUserDocumentAction } from "redux/actions/auth/getPersonalInformationAction";
import updateEnterpriseProfileAction from "redux/actions/updateEnterpriseProfileAction";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStylesUserProfile = makeStyles(componentStylesUserProfile);

function ProfessionalForm(props) {
  const theme = useTheme();
  const classes = { ...useStylesGeneric(), ...useStylesUserProfile() };
  const intl = useIntl();
  const {
    register,
    getValues,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  function handleChangePassword() {
    props.handleChangePassword();
  }
  const getFileInfoByDocumentType = (documentTypeId) => {
    const doc = props.authState.personalInformation?.userDocuments?.find((d) => d.documentTypeId === documentTypeId);
    if (doc)
      return [
        {
          documentId: doc.documentIndex,
          documentName: doc.documentName,
        },
      ];
    return undefined;
  };

  const getOpportunitiesDocumentsMessage = (documents) => {
    return (
      <>
        <Grid item xs={12} md={12}>
          <Typography className={classes.alertMessage}>
            {intl.formatMessage(messages.Message_Admin_User_Profile_User_Documents_Message)}
          </Typography>
          <ul className={classes.alertMessage}>
            {documents?.map((doc, key) => {
              return <li key={key}>{doc.documentName}</li>;
            })}
          </ul>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader classes={{ title: classes.cardHeader }} title={"Dados Profissionais"}></CardHeader>
        <CardContent>
          <Box>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategoryFormLabel}>Nível Académico</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="academicLevel"
                    selectOptions={getDatasetStateByName(props, "AcademicLevel")}
                    getValues={getValues}
                    setValue={setValue}
                    loadChildrenDataSet={props.getDataSetByName}
                    childrenDataSet={DATASET.ACADEMIC_DEGREE}
                    childrenSelect="degree"
                    required={true}
                    requiredMessage={"O campo é obrigatório"}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["academicLevel"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["academicLevel"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategoryForm}>Curso (opcional)</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="degree"
                    selectOptions={getDatasetStateByName(props, DATASET.ACADEMIC_DEGREE)}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategoryFormLabel}>Categoria Profissional (opcional)</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="professionalCategory"
                    selectOptions={getDatasetStateByName(props, "ProfessionalCategory")}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategory}>Formação Profissional</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="professionalTraining"
                    selectOptions={getDatasetStateByName(props, "ProfessionalTraining")}
                    initialLabel="Seleccione opções aplicáveis"
                    getValues={getValues}
                    required={true}
                    requiredMessage={"O campo é obrigatório"}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["professionalTraining"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["professionalTraining"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategory}>Formação de Empreendedorismo</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="entrepreneurshipTraining"
                    selectOptions={getDatasetStateByName(props, "EntrepreneurshipTraining")}
                    initialLabel="Seleccione opções aplicáveis"
                    getValues={getValues}
                    required={true}
                    requiredMessage={"O campo é obrigatório"}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["entrepreneurshipTraining"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["entrepreneurshipTraining"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategory}>Formação de Gestão de Pequenos Negócios</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="smallBusinessMgmtTraining"
                    selectOptions={getDatasetStateByName(props, "SmallBusinessMgmtTraining")}
                    initialLabel="Seleccione opções aplicáveis"
                    getValues={getValues}
                    required={true}
                    requiredMessage={"O campo é obrigatório"}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["smallBusinessMgmtTraining"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["smallBusinessMgmtTraining"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategory}>
                    {intl.formatMessage(labels.Label_UserProfile_UserSingular_ProfessionalData_StudiedLanguages)}
                  </FormLabel>
                  <FormMultipleSelect
                    control={control}
                    fieldName="studiedLanguages"
                    selectOptions={getDatasetStateByName(props, DATASET.LANGUAGES)}
                    initialLabel={intl.formatMessage(labels.Label_SelectMultipleOptions)}
                    getValues={getValues}
                    limitTags={1}
                    required={false}
                    errors={errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategoryForm}>
                    {intl.formatMessage(labels.Label_UserProfile_UserSingular_ProfessionalData_OccupationalCategory)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="userSingular.occupationalCategory"
                    selectOptions={getDatasetStateByName(props, "OccupationalCategories")}
                    getValues={getValues}
                    setValue={setValue}
                    required={false}
                    errors={errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategoryForm}>
                    <>
                      {intl.formatMessage(
                        labels.Label_UserProfile_UserSingular_ProfessionalData_IntendedWorkplaceProvinceId
                      )}
                      <Tooltip
                        title={intl.formatMessage(
                          labels.Label_UserProfile_UserSingular_ProfessionalData_IntendedWorkplaceProvinceId_Details
                        )}
                      >
                        <HelpIcon className={classes.tooltipInfoIcon} />
                      </Tooltip>
                    </>
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="userSingular.intendedWorkplaceProvince"
                    selectOptions={getDatasetStateByName(props, "Provinces")}
                    getValues={getValues}
                    setValue={setValue}
                    required={false}
                    errors={errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategory}>
                    {intl.formatMessage(
                      labels.Label_UserProfile_UserSingular_ProfessionalData_MainProfessionalExperience
                    )}
                  </FormLabel>
                  <FormMultipleSelect
                    control={control}
                    fieldName="professionalExperience"
                    selectOptions={getDatasetStateByName(props, DATASET.PROFESSIONAL_CATEGORY)}
                    initialLabel={intl.formatMessage(labels.Label_SelectMultipleOptions)}
                    getValues={getValues}
                    limitTags={2}
                    required={true}
                    requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["professionalExperience"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["professionalExperience"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="userSingular.experience"
                  label={intl.formatMessage(
                    labels.Label_UserProfile_UserSingular_ProfessionalData_YearsOfMainProfessionalExperience
                  )}
                  numberType={true}
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                    validate: {
                      minValue: (value) => {
                        if (value < 0)
                          return intl.formatMessage(errorMessages.ErrorMessage_Generic_ValueEqualOrGreaterThanZeroField);
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategory}>
                    {intl.formatMessage(
                      labels.Label_UserProfile_UserSingular_ProfessionalData_SecondaryProfessionalExperience
                    )}
                  </FormLabel>
                  <FormMultipleSelect
                    control={control}
                    fieldName="secondaryProfessionalExperience"
                    selectOptions={getDatasetStateByName(props, DATASET.PROFESSIONAL_CATEGORY)}
                    initialLabel={intl.formatMessage(labels.Label_SelectMultipleOptions)}
                    getValues={getValues}
                    limitTags={2}
                    required={false}
                    errors={errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="userSingular.secondaryExperience"
                  label={intl.formatMessage(
                    labels.Label_UserProfile_UserSingular_ProfessionalData_YearsOfSecondaryProfessionalExperience
                  )}
                  numberType={true}
                  control={control}
                  errors={errors}
                  rules={{
                    validate: {
                      minValue: (value) => {
                        if (value < 0)
                          return intl.formatMessage(errorMessages.ErrorMessage_Generic_ValueEqualOrGreaterThanZeroField);
                        return true;
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormInput
                  name="userSingular.professionalQualification"
                  label={intl.formatMessage(
                    labels.Label_UserProfile_UserSingular_ProfessionalData_ProfessionalQualification
                  )}
                  multiline={true}
                  rows={3}
                  control={control}
                  errors={errors}
                />
              </Grid>

              <Grid item xs={12}>
                <FormInput
                  name="userSingular.otherProfessionalExperience"
                  label={intl.formatMessage(
                    labels.Label_UserProfile_UserSingular_ProfessionalData_OtherProfessionalExperience
                  )}
                  multiline={true}
                  rows={3}
                  control={control}
                  errors={errors}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      {props.authState.personalInformation?.userTypeDocuments?.length > 0 && (
        <Card className={classes.cardRoot}>
          <CardHeader classes={{ title: classes.cardHeader }} title={"Documentos"}></CardHeader>
          <CardContent>
            <Grid container>
              {getOpportunitiesDocumentsMessage(props.authState.personalInformation?.userTypeDocuments)}
              {props.authState.personalInformation?.userTypeDocuments?.map((documentType, key) => (
                <Grid item xs={12} md={4} className={classes.paddingRight0px} key={key}>
                  <FormGroup>
                    <FormLabel className={classes.boxNameCategory}>{documentType.documentName}</FormLabel>
                    <DropzoneField
                      name={documentType.documentTypeId.toString()}
                      multiple={false}
                      label={intl.formatMessage(labels.Label_Generic_Upload_Document)}
                      register={register}
                      errors={errors}
                      required={false}
                      setValue={setValue}
                      getValues={getValues}
                      trigger={trigger}
                      dropzoneClass={classes.dropzone}
                    />
                    <SubmitedDocumentsSection
                      files={getFileInfoByDocumentType(documentType.documentTypeId)}
                      getDocument={props.getUserDocument}
                    />
                  </FormGroup>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      )}
      <Card className={classes.cardRoot}>
        <CardHeader classes={{ title: classes.cardHeader }} title={"Palavra-passe"}></CardHeader>
        <CardContent>
          <Box display="flex">
            <Grid item xs={10} md={4} className={classes.paddingRight0px}>
              <FormGroup>
                <FormLabel className={classes.boxNameCategory}>Palavra-passe</FormLabel>
                <FilledInput
                  disabled
                  className={classes.borderRadiusSemiCurved}
                  placeholder="**************"
                ></FilledInput>
              </FormGroup>
            </Grid>
            <Box paddingTop="22.5px" paddingBottom="23px">
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                onClick={handleChangePassword}
                classes={classes.noLeftBorderRadius}
              >
                {" "}
                Alterar palavra-Passe
              </GenericButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateEnterpriseProfile: (data) => dispatch(updateEnterpriseProfileAction(data)),
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  getChildrenByPropertyId: (name, id, addEmptyField) =>
    dispatch(getChildrenByPropertyIdAction(name, id, addEmptyField)),
  getUserDocument: (docId, docName) => dispatch(getUserDocumentAction(docId, docName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalForm);
